@use 'assets/styles/utils/helper' as h;

:root {
  --caption-vert-margin: var(--space-16);
  --full-bleed-vert-margin: var(--space-48);
}

:global(body.today) {
  --inline-image--caption--margin-top: #{h.rem(10px)};
  --caption-vert-margin: var(--space-8);
}
