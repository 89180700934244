@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.container {
  display: flex;
  padding: 12px 8px 8px;
  gap: 20px;
  margin-bottom: 8px;
  background: #fff;
  position: relative;

  @include h.breakpoint-m {
    gap: 40px;
    padding: 12px 20px 8px 12px;
  }
}

.productLink {
  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid transparent;
    cursor: pointer;
    transition: border-color 200ms;
  }

  &:focus {
    outline: none;

    &::before {
      border-color: #34a83e;
    }
  }
}

.teaseImage {
  width: 100px;
  height: 100px;
}

.superlative {
  color: var(--quick-take-superlative-color);
  font-family: var(--quick-take-superlative-font-family);
  font-weight: var(--quick-take-superlative-font-weight);
  font-size: var(--quick-take-superlative-font-size);
  line-height: var(--quick-take-superlative-line-height);
  position: relative;
  z-index: 1;
}

.productName {
  color: var(--quick-take-product-name-color);
  font-family: var(--quick-take-product-name-font-family);
  font-weight: var(--quick-take-product-name-font-weight);
  font-size: var(--quick-take-product-name-font-size);
  line-height: var(--quick-take-product-name-line-height);
  margin: 8px 0;
}

.listPrice {
  color: var(--quick-take-list-price-color);
  font-family: var(--quick-take-list-price-font-family);
  font-weight: var(--quick-take-list-price-font-weight);
  font-size: var(--quick-take-list-price-font-size);
  line-height: var(--quick-take-list-price-line-height);
}

.salePrice {
  display: none;
  color: var(--quick-take-sale-price-color);
  font-family: var(--quick-take-sale-price-font-family);
  font-weight: var(--quick-take-sale-price-font-weight);
  font-size: var(--quick-take-sale-price-font-size);
  line-height: var(--quick-take-sale-price-line-height);
}

.onSale {
  .listPrice {
    font-size: var(--quick-take-list-price-on-sale-font-size);
    font-weight: var(--quick-take-list-price-on-sale-font-weight);
    text-decoration-line: line-through;
  }

  .salePrice {
    display: block;
    color: var(--quick-take-sale-price-color);
    font-family: var(--quick-take-sale-price-font-family);
    font-weight: var(--quick-take-sale-price-font-weight);
    font-size: var(--quick-take-sale-price-font-size);
    line-height: var(--quick-take-sale-price-line-height);
  }
}

.rightCol {
  flex: 1;
  padding-bottom: 25px;
}

.prices {
  flex: 1;
}

.offer {
  display: flex;
  width: 100%;
}

.seller {
  display: flex;
  align-items: center;
}

.sellerName {
  display: block;
  text-transform: uppercase;
  color: var(--quick-take-seller-name-color);
  font-family: var(--quick-take-seller-name-font-family);
  font-weight: var(--quick-take-seller-name-font-weight);
  font-size: var(--quick-take-seller-name-font-size);
  line-height: var(--quick-take-seller-name-line-height);
  border-bottom: var(--quick-take-seller-name-border-bottom);
}

.learnMoreContainer {
  padding: 8px 8px 0;
  position: absolute;
  left: 50%;
}

.learnMore {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--quick-take-learn-more-color);
  font-family: var(--quick-take-learn-more-font-family);
  font-weight: var(--quick-take-learn-more-font-weight);
  font-size: var(--quick-take-learn-more-font-size);
  line-height: var(--quick-take-learn-more-line-height);

  .downArrow {
    margin-left: 6px;

    path {
      fill: var(--quick-take-learn-more-arrow-color);
      stroke: var(--quick-take-learn-more-arrow-color);
    }
  }
}
