@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.expandableList {
  background-color: transparent;
  left: 0;
  min-width: h.rem(375px);
  margin: h.rem(24px 0 32px 0);
  max-width: var(--expandable-list-max-width);
  position: relative;

  @include h.breakpoint-x {
    left: h.rem(-20px);
    width: 580px;
  }

  @media only screen and (max-width: (579px)) {
    margin: h.rem(24px -20px 32px -20px);
  }
}

.expandableList.expandableListAmp {
  margin: h.rem(24px 0 32px);
}

.expandableListHeader {
  background-color: transparent;
  color: var(--expandable-list-header-color);
  height: h.rem(52px);
}

.expandableListHeaderTab {
  background-color: var(--expandable-list-header-tab-background-color);
  max-width: var(--expandable-list-header-tab-max-width);
  overflow: hidden;
  padding: h.rem(8px 40px 8px 20px);
  position: relative;
  top: var(--space-20);
  white-space: nowrap;
}

.expandableListTitle {
  display: var(--expandable-list-title-display);
  font-family: var(--expandable-list-title-font-family);
  font-size: var(--expandable-list-title-font-size);
  font-weight: var(--expandable-list-title-font-weight);
  line-height: var(--expandable-list-title-line-height);
  margin: 0;
  width: fit-content;
  max-width: var(--expandable-list-title-max-width);
  overflow: var(--expandable-list-title-overflow);
  padding-right: var(--expandable-list-title-padding-right);
  position: var(--expandable-list-title-position);
  text-wrap: none;
  z-index: var(--expandable-list-title-z-index);

  &::after {
    background-color: var(--expandable-list-title-after-background-color);
    bottom: var(--expandable-list-title-after-bottom);
    box-sizing: var(--expandable-list-title-after-box-sizing);
    clip-path: var(--expandable-list-title-after-clip-path);
    content: var(--expandable-list-title-after-content);
    height: var(--expandable-list-title-after-height);
    left: var(--expandable-list-title-after-left);
    max-width: var(--expandable-list-title-after-max-width);
    position: var(--expandable-list-title-after-position);
    top: var(--expandable-list-title-after-top);
    width: var(--expandable-list-title-after-width);
    z-index: var(--expandable-list-title-after-z-index);
  }
}

.expandableListSubtitle {
  display: var(--expandable-list-subtitle-display);
  font-family: var(--expandable-list-subtitle-font-family);
  font-size: var(--expandable-list-subtitle-font-size);
  font-weight: var(--expandable-list-subtitle-font-weight);
  line-height: var(--expandable-list-subtitle-line-height);
  margin: 0;
  padding: var(--expandable-list-subtitle-padding);
  position: relative;
  text-wrap: none;
}

.expandableListContainer {
  background-color: var(--expandable-list-container-background-color);
  padding: var(--expandable-list-container-padding);
  margin-top: 20px;

  &::before {
    background-color: var(--expandable-list-container-background-color);
    clip-path: var(--expandable-list-container-before-clip-path);
    content: var(--expandable-list-container-before-content);
    display: var(--expandable-list-container-before-display);
    height: var(--expandable-list-container-before-height);
    left: var(--expandable-list-container-before-left);
    position: var(--expandable-list-container-before-position);
    top: var(--expandable-list-container-before-top);
    width: var(--expandable-list-container-before-width);
  }
}

:global(.select) .expandableListContainer {
  margin-top: 0;
}

.expandableListContainerNoExpand {
  padding: h.rem(40px 15px 32px 15px);
}

.expandableListContainerNoSubtitle {
  padding-top: var(--expandable-list-container-padding-top-no-subtitle);
}

.expandableListAffordance {
  height: h.rem(50px);
  overflow: hidden;

  article,
  div {
    height: h.rem(50px);
    overflow: hidden;
  }
}

.expandableListOverlay {
  background: linear-gradient(0deg, var(--expandable-list-container-background-color) 7.95%, rgba(215, 237, 255, 0) 94.27%);
  height: h.rem(50px);
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 2;
}

.expandableListButton {
  align-items: center;
  background-color: var(--expandable-list-container-background-color);
  border: none;
  color: var(--grey-100);
  display: flex;
  font-family: var(--expandable-list-button-font-family);
  font-size: var(--text-12);
  font-weight: 400;
  flex-direction: row;
  gap: h.rem(6px);
  justify-content: center;
  padding: var(--expandable-list-button-padding);
  position: relative;
  line-height: h.rem(18px);
  text-transform: uppercase;
  top: -1em;
  width: 100%;

  &::after {
    background-color: var(--expandable-list-container-background-color);
    clip-path: var(--expandable-list-button-after-clip-path);
    content: var(--expandable-list-button-after-content);
    display: var(--expandable-list-button-after-display);
    height: var(--expandable-list-button-after-height);
    position: var(--expandable-list-button-after-position);
    top: var(--expandable-list-button-after-top);
    width: var(--expandable-list-button-after-width);
  }
}

.icon {
  color: var(--expandable-list-button-icon-color);
}

.iconDown {
  rotate: 90deg;
}

.iconUp {
  rotate: 270deg;
}
