@use 'assets/styles/utils/helper' as h;

:root {
  --show-more-button-wrapper-before-bottom: #{h.rem(35px)};
  --show-more-button-wrapper-justify-content: center;
}

:global(body.today) {
  --show-more-button-wrapper-before-bottom: #{h.rem(46px)};
  --show-more-button-wrapper-justify-content: flex-start;
}

:global(body.select) {
  --button-text-color: var(--blue-60);
  --button-border-color: var(--blue-60);

  :hover {
    --button-background-color: var(--blue-60);
    --button-border-hover-color: var(--blue-60);
  }
}
