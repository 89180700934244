@use 'assets/styles/utils/helper' as h;

:root {
  --article-faqembed-vertical-color: var(--default-color);
  --article-faqembed-headline-font-family: var(--founders-cond);
  --article-faqembed-headline-font-color: var(--default-color);
  --article-faqembed-headline-font-size: 30px;
  --article-faqembed-headline-font-weight: 600;
  --article-faqembed-headline-accent-line-bottom: calc((var(--article-faqembed-headline-line-height) / 2) - 5px);
  --article-faqembed-card-font-family: var(--founders-cond);
  --article-faqembed-card-font-color: inherit;
  --article-faqembed-card-border-bottom: 1px solid var(--grey-40);
  --article-faqembed-card-margin-bottom: 24px;
  --article-faqembed-card-padding-bottom: 24px;
  --article-faqembed-card-link-color: var(--default-color);
  --article-faqembed-card-link-hover-color: var(--default-color);
  --article-faqembed-card-link-hover-opacity: var(--default-hover-opacity);
  --article-faqembed-card-link-active-opacity: var(--default-active-opacity);
  --article-faqembed-card-link-underline-color: transparent;
  --article-faqembed-card-link-underline-padding: 0;
  --article-faqembed-card-link-underline-width: 0;
  --article-faqembed-card-question-font-family: var(--founders-cond);
  --article-faqembed-card-question-font-color: var(--grey-70);
  --article-faqembed-card-question-font-weight: 600;
  --article-faqembed-card-answer-font-family: var(--publico-txt);
  --article-faqembed-card-answer-font-color: var(--grey-70);
  --article-faqembed-card-answer-font-weight: 400;
  --article-faqembed-card-answer-margin: 1.5rem 0;
  --article-faqembed-bullet-color: var(--default-color);
  --article-faqembed-list-item-padding-left: initial;

  @include h.breakpoint-s-only {
    --article-faqembed-headline-line-height: #{h.rem(35px)};
    --article-faqembed-card-question-font-size: #{h.rem(20px)};
    --article-faqembed-card-question-line-height: #{h.rem(23px)};
    --article-faqembed-card-answer-font-size: #{h.rem(16px)};
    --article-faqembed-card-answer-line-height: #{h.rem(24px)};
  }

  @include h.breakpoint-m {
    --article-faqembed-headline-line-height: #{h.rem(39px)};
    --article-faqembed-card-question-font-size: #{h.rem(24px)};
    --article-faqembed-card-question-line-height: #{h.rem(27px)};
    --article-faqembed-card-answer-font-size: #{h.rem(18px)};
    --article-faqembed-card-answer-line-height: #{h.rem(27px)};
  }
}

body.today {
  --article-faqembed-headline-font-color: var(--grey-70);
  --article-faqembed-headline-font-family: var(--secondary-font);
  --article-faqembed-headline-font-size: 32px;
  --article-faqembed-headline-font-weight: 700;
  --article-faqembed-headline-accent-line-bottom: calc((var(--article-faqembed-headline-line-height) / 2) - 3px);
  --article-faqembed-card-font-family: var(--tertiary-font);
  --article-faqembed-card-question-font-family: var(--secondary-font);
  --article-faqembed-card-question-font-weight: 700;
  --article-faqembed-card-answer-font-family: var(--tertiary-font);
  --article-faqembed-card-link-color: var(--grey-70);
  --article-faqembed-card-link-hover-color: var(--primary-color);
  --article-faqembed-card-link-hover-opacity: 1;
  --article-faqembed-card-link-active-opacity: 1;
  --article-faqembed-card-link-underline-color: var(--accent-orange);
  --article-faqembed-card-link-underline-padding: 0;
  --article-faqembed-card-link-underline-width: 2px;
  --article-faqembed-list-item-padding-left: #{h.rem(18px)};

  @include h.breakpoint-s-only {
    --article-faqembed-headline-line-height: #{h.rem(39px)};
    --article-faqembed-card-question-font-size: #{h.rem(18px)};
    --article-faqembed-card-question-line-height: #{h.rem(27px)};
    --article-faqembed-card-answer-font-size: #{h.rem(20px)};
    --article-faqembed-card-answer-line-height: #{h.rem(30px)};
  }

  @include h.breakpoint-m {
    --article-faqembed-headline-line-height: #{h.rem(48px)};
    --article-faqembed-card-question-font-size: #{h.rem(20px)};
    --article-faqembed-card-question-line-height: #{h.rem(30px)};
    --article-faqembed-card-answer-font-size: #{h.rem(20px)};
    --article-faqembed-card-answer-line-height: #{h.rem(30px)};
  }
}

body.select {
  --article-faqembed-card-border-bottom: 1px solid #ccc;
  //Headline
  --article-faqembed-headline-font-color: var(--grey-70); //check
  --article-faqembed-headline-font-family: var(--primary-font);
  --article-faqembed-headline-font-weight: 800; //check
  --article-faqembed-headline-accent-line-bottom: calc((var(--article-faqembed-headline-line-height) / 2) - 5px); // check
  // Card
  --article-faqembed-card-font-family: var(--primary-font); // check
  --article-faqembed-card-question-font-color: var(--blue-60);
  --article-faqembed-card-question-font-family: var(--primary-font); // check
  --article-faqembed-card-question-font-weight: 700;
  --article-faqembed-card-answer-font-family: var(--primary-font); // check
  --article-faqembed-card-answer-line-height: 150%;
  --article-faqembed-card-answer-margin: 16px 0 24px 0;
  // Card Link
  --article-faqembed-card-link-color: var(--blue-60);
  --article-faqembed-card-link-hover-color: var(--blue-60);
  --article-faqembed-card-link-hover-opacity: .7;
  --article-faqembed-card-link-active-opacity: .5;
  --article-faqembed-card-link-underline-color: transparent; // check
  --article-faqembed-card-link-underline-padding: 0;
  --article-faqembed-card-link-underline-width: 0;
  --article-faqembed-list-item-padding-left: #{h.rem(18px)};
  --article-faqembed-card-question-margin-bottom-active: #{h.rem(16px)};

  @include h.breakpoint-s-only {
    --article-faqembed-card-margin-bottom: 18px;
    --article-faqembed-card-padding-bottom: 18px;
    --article-faqembed-headline-line-height: #{h.rem(36px)};
    --article-faqembed-card-question-font-size: #{h.rem(14px)};
    --article-faqembed-card-question-line-height: #{h.rem(14px)};
    --article-faqembed-card-answer-font-size: #{h.rem(14px)};
    --article-faqembed-card-answer-line-height: #{h.rem(18px)};
  }

  @include h.breakpoint-m {
    --article-faqembed-headline-line-height: #{h.rem(36px)};
    --article-faqembed-card-question-font-size: #{h.rem(18px)};
    --article-faqembed-card-question-line-height: #{h.rem(18px)};
    --article-faqembed-card-answer-font-size: #{h.rem(18px)};
    --article-faqembed-card-answer-line-height: #{h.rem(28px)};
  }
}
