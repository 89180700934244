@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss' as t;

.gridContainerLarge {
  margin-left: calc(20px - 2vw);
  margin-right: calc(20px - 2vw);

  @include h.breakpoint-m {
    margin-left: calc(20px - 4vw);
    margin-right: calc(20px - 4vw);
  }

  @include h.breakpoint-l {
    margin-left: auto;
    margin-right: auto;
    width: 62.5rem;
  }

  @include h.breakpoint-x {
    width: 77.5rem;
  }

  :global(body.today),
  :global(body.select) & {
    margin-left: 0;
    margin-right: 0;
  }
}
