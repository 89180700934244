@use 'assets/styles/utils/helper' as h;

.related {
  --related-margin: 0 0 8px;
  --related-padding: 0;
  --related-non-prefixed-margin: var(--space-48) 0;
  --related-flex-direction: inherit;
  --related-title-display: flex;
  --related-title-after-content: ' ';
  --related-title-after-height: var(--space-1);
  --related-title-after-margin-left: var(--space-12);
  --related-title-color: var(--default-color);
  --related-title-font-size: var(--text-20);
  --related-title-text-transform: capitalize;
  --related-title-background-color: var(--default-color);
  --related-title-background-image: none;
  --related-title-background-repeat: unset;
  --related-title-background-size: unset;
  --related-title-background-max-height: var(--space-112);
  --related-title-font-family: var(--default-headline-font-family);
  --related-title-font-style: normal;
  --related-title-leading: var(--leading-100);
  --related-title-font-weight: normal;
  --related-title-border-color: var(--default-color);
  --related-items-after-content: ' ';
  --related-items-max-height: initial;
  --related-item-background-color: var(--grey-20);
  --related-items-padding: 0;
  --related-items-margin-left: 0;
  --related-items-flex-direction: row;
  --unibrow-height: var(--space-32);
  --border-width: var(--space-1);
  --related-item-eyebrow-height: calc(100% - var(--unibrow-height) - var(--border-width));
  --related-title-nbcnews-commerce-color: inherit;
  --related-title-nbcnews-commerce-background-color: inherit;
  --related-title-nbcnews-commerce-border-styles: inherit;
  --related-title-nbcnews-commerce-background-max-height: initial;
  --related-title-betternews-commerce-background-max-height: initial;

  @include h.breakpoint-m {
    --related-title-display: inline-block;
    --related-title-after-content: none;
    --related-title-after-height: 0;
    --related-title-after-margin-left: 0;
    --related-title-color: var(--white);
    --related-title-font-size: var(--text-24);
    --related-title-padding: var(--space-24) var(--space-40) var(--space-20);
    --related-items-padding: var(--space-20) var(--space-20) 0;
    --related-items-margin-left: var(--space-40);
  }

  @include h.breakpoint-x {
    --related-non-prefixed-width: calc(100% + var(--space-100));
    --related-non-prefixed-margin-left: calc(-1 * var(--space-100));
  }

  body.news & {
    --related-title-nbcnews-commerce-color: var(--blue-70);
    --related-title-nbcnews-commerce-background-color: var(--blue-70);
    --related-title-nbcnews-commerce-border-styles: solid var(--space-1) var(--blue-70);
    --related-title-nbcnews-commerce-background-max-height: 110px;
  }

  body.better & {
    --related-title-betternews-commerce-background-max-height: 110px;
  }

  body.noticias & {
    --related-item-background-color: var(--neutral-10);
    --related-title-background-color: var(--blue-60);
  }

  body.today & {
    --related-title-after-content: none;
    --related-items-max-height: 475px;
    --related-item-background-color: transparent;
    --related-item-eyebrow-height: 119px;
    --related-non-prefixed-margin: 65px 0;
    --related-non-prefixed-margin-left: 0;
    --related-non-prefixed-width: auto;
    --related-title-leading: 1.5;
    --related-title-font-family: var(--secondary-font);
    --related-title-font-size: var(--text-18);
    --related-title-font-style: normal;
    --related-title-font-weight: 700;
    --related-title-color: var(--grey-60);
    --related-title-padding: 0;
    --related-title-background-color: transparent;
    --related-title-after-margin-left: 0;
    --related-items-margin-left: 0;
    --related-items-padding: 0;

    @include h.breakpoint-m {
      --related-margin: 0 0 8px 8.33333%;
      --related-non-prefixed-margin: 80px 0 60px;
      --related-title-font-size: var(--text-24);
      --related-title-leading: 1.5;
    }

    @include h.breakpoint-l {
      --related-margin: 0;
      --related-non-prefixed-margin: 68px 0 48px;
    }
  }

  body.think & {
    --related-title-font-family: var(--founders-cond);
  }

  body.select & {
    --related-title-font-family: var(--lato);
    --related-title-background-color: transparent;
    --related-title-border-color: transparent;
    --related-title-color: var(--neutral-60);
    --related-title-font-weight: 900;
    --related-title-font-size: #{h.rem(18px)};
    --related-title-leading: #{h.rem(22px)};
    --related-title-background-image: var(--default-background-image);
    --related-title-background-repeat: repeat-x;
    --related-title-background-size: 18px;
    --related-title-display: flex;
    --related-title-after-content: ' ';
    --related-title-after-height: 18px;
    --related-title-after-margin-left: 12px;
    --related-title-text-transform: uppercase;
    --related-title-padding: 48px 0 0;
    --related-item-background-color: var(--white);
    --related-items-padding: 0;
    --related-items-margin-left: 0;
    --related-items-flex-direction: row;
    --related-flex-direction: column;
    --related-margin: 0;

    @include h.breakpoint-m {
      --related-title-display: flex;
      --related-title-padding: 48px 0 0;
      --related-margin: 0 30px;
      --related-padding: 0 30px;
    }

    @include h.breakpoint-l {
      --related-padding: unset;
      --related-non-prefixed-margin-left: unset;
      --related-non-prefixed-width: unset;
      --related-title-padding: 0;
      --related-items-flex-direction: column;
      --related-margin: 0;
    }
  }

  body.today.packageEmbedPage & {
    --related-margin: 0;
  }

  body.today.articlePage & {
    @include h.breakpoint-s-only {
      --related-margin: 0;
    }
  }
}
