@use 'assets/styles/utils/helper' as h;

.contentModule {
  --background: #fff;
  --border-color: var(--primary-color);
  --border-section-color: #808488;
  --border: 2px solid var(--border-color);
  --spacing: 30px;
  background: var(--background);
  border: var(--border);
  box-sizing: border-box;
  padding: var(--spacing);
  position: relative;

  .header {
    border-bottom: 1px dashed var(--border-section-color);
    margin: 0 0 18px;
    padding: 0 0 18px;
  }

  .headline {
    font-family: FoundersGroteskCond, Mada, sans-serif;
    font-size: 30px;
    font-weight: 700;
    line-height: 32px;
    margin: 0 0 16px;
  }

  .description {
    margin: 0;
  }

  .footer {
    border-top: 1px dashed var(--border-section-color);
    margin: 18px 0 0;
    padding: 18px 0 0;
  }

  .timestamp {
    display: block;
    font-family: FoundersGroteskMono, 'Courier New', Courier, monospace;
    font-size: 12px;
    letter-spacing: -0.5px;
  }

  /* News Article */
  &.news {
    box-shadow: 5px 5px 0 5px #dfebfe;
  }

  /* News Live Blog */
  &.liveBlog {
    --background: #fff;
    --border-color: var(--article-summarybox-breaking-red);
    box-shadow: none;
    z-index: 0;

    [class*='stripe-pattern'] {
      bottom: -10px;
      left: -2px;
      right: -10px;
      top: -2px;
      z-index: -1;
    }

    &::after {
      background: var(--background);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;
    }
  }

  /* MSNBC Article */
  &.msnbc {
    --background: var(--default-neutral);
    --border: none;
  }

  /* Today Article */
  &.today {
    --background: var(--grey-20);
    --border: none;
    --spacing: 40px 50px;
    background: none;
    z-index: 0;

    @include h.breakpoint-m-max {
      --spacing: 35px 30px;
    }

    &::before {
      background: var(--background);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transform: skewY(1.22deg);
      width: 100%;
      z-index: -1;
    }

    &::after {
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;
    }

    .footer {
      border-top: none;
      padding: 0;
    }
  }
}
