@use 'assets/styles/utils/helper' as h;

:global(body.select) {
  --expandable-list-max-width: #{h.rem(580px)};

  // Header
  --expandable-list-header-color: var(--white);

  // Header Tab
  --expandable-list-header-tab-background-color: #0477c9;
  --expandable-list-header-tab-max-width: #{h.rem(335px)};

  // Title
  --expandable-list-title-display: block;
  --expandable-list-title-font-family: var(--lato);
  --expandable-list-title-font-size: var(--text-30);
  --expandable-list-title-font-weight: 900;
  --expandable-list-title-line-height: var(--space-36);
  --expandable-list-title-max-width: #{h.rem(275px)};
  --expandable-list-title-overflow: hidden;
  --expandable-list-title-padding-right: var(--space-8);
  --expandable-list-title-position: unset;
  --expandable-list-title-z-index: unset;
  --expandable-list-title-after-content: unset;

  // Subtitle
  --expandable-list-subtitle-display: inline-block;
  --expandable-list-subtitle-font-family: var(--lato);
  --expandable-list-subtitle-font-size: var(--text-20);
  --expandable-list-subtitle-font-weight: 400;
  --expandable-list-subtitle-line-height: var(--space-24);
  --expandable-list-subtitle-padding: 0 0 0 var(--space-8);

  // List Container
  --expandable-list-container-background-color: #d7edff;
  --expandable-list-container-padding: #{h.rem(40px 15px 0)};
  --expandable-list-container-padding-top-no-subtitle: #{h.rem(40px)};
  --expandable-list-container-before-content: unset;

  // Button
  --expandable-list-button-icon-color: var(--blue-60);
  --expandable-list-button-font-family: var(--lato);
  --expandable-list-button-padding: var(--space-12) 0;
  --expandable-list-button-after-content: unset;
}

:global(body.today) {
  --expandable-list-max-width: #{h.rem(600px)};
  --expandable-list-header-color: var(--grey-70);
  --expandable-list-header-tab-background-color: transparent;
  --expandable-list-header-tab-max-width: unset;

  // Title
  --expandable-list-title-display: block;
  --expandable-list-title-font-family: var(--primary-font);
  --expandable-list-title-font-size: var(--text-28);
  --expandable-list-title-font-weight: 400;
  --expandable-list-title-line-height: 150%;
  --expandable-list-title-max-width: unset;
  --expandable-list-title-overflow: unset;
  --expandable-list-title-padding-right: var(--space-8);
  --expandable-list-title-position: relative;
  --expandable-list-title-z-index: 3;
  --expandable-list-title-after-background-color: #fff38b;
  --expandable-list-title-after-bottom: calc(1.75rem * 1.5 * .11);
  --expandable-list-title-after-box-sizing: border-box;
  --expandable-list-title-after-clip-path: polygon(0 0, 98% 60%, 100% 100%, 0 100%);
  --expandable-list-title-after-content: '';
  --expandable-list-title-after-height: calc(1.75rem * 1.5 * .33);
  --expandable-list-title-after-left: 0;
  --expandable-list-title-after-max-width: calc(100% + 3.125rem);
  --expandable-list-title-after-position: absolute;
  --expandable-list-title-after-top: h.rem(25px);
  --expandable-list-title-after-width: calc(100% + 1.875rem);
  --expandable-list-title-after-z-index: -1;

  // Subtitle
  --expandable-list-subtitle-display: inline-block;
  --expandable-list-subtitle-font-family: var(--tertiary-font);
  --expandable-list-subtitle-font-size: var(--text-18);
  --expandable-list-subtitle-font-weight: 400;
  --expandable-list-subtitle-line-height: 150%;
  --expandable-list-subtitle-padding: 0 0 10px 0;

  // Container
  --expandable-list-container-background-color: #fff2eb;
  --expandable-list-container-padding: #{h.rem(40px 15px 0)};
  --expandable-list-container-padding-top-no-subtitle: #{h.rem(20px)};
  --expandable-list-container-before-clip-path: polygon(0 0, 100% 40%, 100% 100%, 0% 100%);
  --expandable-list-container-before-content: '';
  --expandable-list-container-before-background-color: var(--expandable-list-container-background-color);
  --expandable-list-container-before-height: #{h.rem(17px)};
  --expandable-list-container-before-width: 100%;
  --expandable-list-container-before-position: absolute;
  --expandable-list-container-before-top: #{h.rem(56px)};
  --expandable-list-container-before-left: 0;
  --expandable-list-container-before-display: block;

  // Button
  --expandable-list-button-icon-color: #ff503c;
  --expandable-list-button-font-family: var(--secondary-font);
  --expandable-list-button-padding: var(--space-12) 0 0 0;
  --expandable-list-button-after-background-color: var(--expandable-list-container-background-color);
  --expandable-list-button-after-clip-path: polygon(0 0, 100% 0%, 100% 60%, 0% 100%);
  --expandable-list-button-after-content: '';
  --expandable-list-button-after-display: block;
  --expandable-list-button-after-height: #{h.rem(12px)};
  --expandable-list-button-after-position: absolute;
  --expandable-list-button-after-top: #{h.rem(30px)};
  --expandable-list-button-after-width: 100%;
}
