@use 'assets/styles/utils/helper' as h;

.nfw-anchor {
  z-index: h.get-z-index('shopping-cart');
  position: relative;

  .nfw-cart-add-all-products {
    color: #000;
  }
}
