@use 'assets/styles/utils/helper' as h;

.recommended--sticky {
  position: relative;

  @include h.breakpoint-l {
    position: sticky;
    top: var(--space-112);
  }
}

@include h.breakpoint-s-only {
  .today.articlePage .body-bottom-recommended .recommended-intersection-ref {
    margin: 70px 0;
  }
}
