@use 'assets/styles/utils/helper' as h;
@use './theme.tokens';

.image-liftout {
  margin: 3rem h.$neg-outer-gutter h.rem(40px);

  @include h.breakpoint-m {
    margin-left: -4vw;
    margin-right: -4vw;

    @include h.grid-custom-column(7, 6);
  }

  @include h.breakpoint-l {
    width: calc(100% + 80px);
    margin-left: h.$neg-outer-gutter * 2;
    margin-right: h.$neg-outer-gutter * 2;
  }
}

.image-liftout__overlay {
  border-top: var(--image-liftout--overlay--border-top);
  position: relative;
  display: block;

  @include h.breakpoint-m {
    width: 100vw;
    max-width: 100%;

    @include h.grid-custom-push(-0.5, 12);
  }

  @include h.breakpoint-l {
    margin-left: 40px;
    width: calc(160% - 104px);
    max-width: none;
  }

  @include h.breakpoint-x {
    margin-left: calc(-33.33333% + 53.33333px);
    width: calc(200% - 120px);
  }
}

.image-liftout__overlay--opacity-title,
.image-liftout__overlay--opacity-subtitle {
  background-color: var(--image-liftout--overlay--background-color);
}

.image-liftout__image {
  width: 100%;
}

.image-liftout__label-wrapper {
  body.today & {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.image-liftout__label {
  font-family: var(--image-liftout--label--font-family);
  font-weight: var(--normal);
  font-size: var(--image-liftout--label--font-size);
  line-height: 1;
  color: var(--image-liftout--label--color);
  text-align: center;
  display: block;
  position: var(--image-liftout--label--position);
  top: 50%;
  left: 0;
  transform: var(--image-liftout--label--transform);
  width: var(--image-liftout--label--width);
}

.image-liftout__caption {
  margin-top: var(--image-liftout--caption--margin-top);
  margin-bottom: 0;

  @include h.breakpoint-s-only {
    margin: 0 var(--outer-gutter) 0 var(--outer-gutter);
  }

  @include h.breakpoint-m {
    @include h.grid-custom-push(0.5, 8);
  }

  @include h.breakpoint-l {
    @include h.grid-custom-column(12, 7.5);
  }

  @include h.breakpoint-x {
    @include h.grid-custom-column(12, 6);
    margin-left: 0;
  }

  body.today & {
    @include h.breakpoint-m {
      width: 100vw;
      max-width: 100%;

      @include h.grid-custom-push(-0.5, 12);
    }

    @include h.breakpoint-l {
      margin-left: 80px;
      width: calc(160% - 144px);
      margin-right: 40px;
      max-width: none;
    }

    @include h.breakpoint-x {
      margin-left: calc(-33.33333% + 93.33333px);
      width: calc(200% - 120px);
    }
  }
}
