@use 'assets/styles/utils/helper' as h;

:root {
  --image-liftout--caption--margin-top: 1rem;
  --image-liftout--label--color: var(--white);
  --image-liftout--label--font-family: var(--founders-cond);
  --image-liftout--label--font-size: #{h.rem(25px)};
  --image-liftout--label--margin: 0;
  --image-liftout--label--position: absolute;
  --image-liftout--label--transform: translateY(-50%);
  --image-liftout--label--width: 100%;
  --image-liftout--overlay--background-color: rgba(0, 0, 0, 0.35);
  --image-liftout--overlay--border-top: none;

  @include h.breakpoint-m {
    --image-liftout--label--font-size: #{h.rem(40px)};
  }

  @include h.breakpoint-l {
    --image-liftout--label--font-size: #{h.rem(50px)};
  }

  @include h.breakpoint-x {
    --image-liftout--label--font-size: #{h.rem(60px)};
  }
}

body.today {
  --image-liftout--caption--margin-top: 0;
  --image-liftout--label--font-family: var(--primary-font);
  --image-liftout--label--font-size: #{h.rem(28px)};
  --image-liftout--label--margin: auto;
  --image-liftout--label--position: static;
  --image-liftout--label--transform: none;
  --image-liftout--label--width: #{h.rem(335px)};
  --image-liftout--overlay--background-color: none;
  --image-liftout--overlay--border-top: #{h.rem(22px)} solid var(--accent-purple);

  @include h.breakpoint-m {
    --image-liftout--label--font-size: #{h.rem(48px)};
    --image-liftout--label--width: #{h.rem(575px)};
  }

  @include h.breakpoint-l {
    --image-liftout--label--font-size: #{h.rem(60px)};
    --image-liftout--label--width: #{h.rem(760px)};
  }

  @include h.breakpoint-x {
    --image-liftout--label--font-size: #{h.rem(80px)};
  }
}
