@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;
@use 'sass:math';

.inlineImage {
  display: block;
  padding: 0;
  width: 100vw;

  &.small {
    margin: h.rem(24px) 0 h.rem(24px) #{h.$outer-gutter * -1};

    @include h.breakpoint-m {
      float: left;
      margin-top: h.rem(8px);
      margin-left: calc(8.3333vw * -1);
      margin-right: 4vw;
      width: calc(8.3333vw * 5 + 4vw);

      &::after {
        display: block;
        content: '';
        clear: both;
      }
    }

    @include h.breakpoint-l {
      margin-left: 0;
      margin-right: h.rem(40px);
      width: h.rem(320px);
    }

    @include h.breakpoint-x {
      margin-left: h.rem(-100px);
      width: h.rem(360px);
    }
  }

  &.medium {
    margin: h.rem(48px) 0 h.rem(48px) #{h.$outer-gutter * -1};

    @include h.breakpoint-m {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }
  }

  &.large {
    margin: h.rem(48px) 0 h.rem(48px) #{h.$outer-gutter * -1};

    @include h.breakpoint-m {
      margin-left: calc(8.3333vw * -1);
      width: calc(100vw - #{h.$outer-gutter * 2});
    }

    @include h.breakpoint-l {
      margin-left: 0;
      width: 920px;
    }

    @include h.breakpoint-x {
      margin-left: h.rem(-100px);
      width: 860px;
    }
  }

  &.noCaption {
    &.small,
    &.portrait {
      margin-bottom: h.rem(40px);
    }
  }

  &.electionCard {
    width: 100%;
    margin-inline: 0;
  }
}

.image {
  display: block;
  font-size: 0;
  height: auto;
  line-height: 0;
  margin: 0;
  padding: 0;
  width: 100%;

  img {
    height: auto;
  }
}

.caption {
  float: none;
  margin: var(--caption-vert-margin) h.$outer-gutter 0;
  width: auto;

  @include h.breakpoint-m {
    margin: var(--caption-vert-margin) 0 0;
  }
}

.fullBleedContainer {
  margin: var(--full-bleed-vert-margin) 0;

  .fullBleed {
    position: relative;
    width: 100vw;
    margin-right: 0;
    margin-left: -50vw;
    left: 50%;

    @include h.breakpoint-l {
      left: calc(50% + 180px);
    }

    @include h.breakpoint-x {
      left: calc(50% + 100px);
    }

    +.caption {
      margin-left: 0;
    }
  }
}

// type overrides

:global(.live-blog-card) .inlineImage {
  margin: h.rem(24) auto 0;
  width: 100%;

  &.noCaption {
    margin: h.rem(24) auto;
  }
}

:global(.recipe-body__instructions) .inlineImage {
  margin: h.rem(18px) auto h.rem(24px);
  width: 100%;
}
