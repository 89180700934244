@use 'assets/styles/utils/helper' as h;

$icon-checkmark-option: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="50" viewBox="0 0 50 50"> <defs> <path id="a" d="M0 17.705h24.893V0H0z"/> </defs> <g fill="none" fill-rule="evenodd"> <path stroke="#CCC" d="M.5.5h49v49H.5z"/> <g transform="translate(13 16)"> <mask id="b" fill="#fff"> <use xlink:href="#a"/> </mask> <path fill="#CCC" d="M7.936 17.08L0 10.468l1.363-1.634 7.19 6s5.59-5.593 7.699-7.7C18.63 4.758 21.009 2.38 23.386 0L24.893 1.5a8774.28 8774.28 0 0 1-7.037 7.036 7496.248 7496.248 0 0 0-8.48 8.479l-.688.689-.752-.624z" mask="url(#b)"/> </g> </g></svg>');
$icon-checkmark-choice: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="50" viewBox="0 0 50 50"> <defs> <path id="a" d="M0 0h50v50H0z"/> <path id="b" d="M0 17.705h24.893V0H0z"/> </defs> <g fill="none" fill-rule="evenodd"> <g> <use fill="#FF503C" xlink:href="#a"/> <path stroke="#FF503C" d="M.5.5h49v49H.5z"/> </g> <g transform="translate(13 16)"> <mask id="c" fill="#fff"> <use xlink:href="#b"/> </mask> <path fill="#FFF" d="M7.936 17.08L0 10.468l1.363-1.634 7.19 6s5.59-5.593 7.699-7.7C18.63 4.758 21.009 2.38 23.386 0L24.893 1.5a8774.28 8774.28 0 0 1-7.037 7.036 7496.248 7496.248 0 0 0-8.48 8.479l-.688.689-.752-.624z" mask="url(#c)"/> </g> </g></svg>');

.container {
  margin: 0;

  .pollWrapper {
    width: 100vw;
    margin-left: h.rem(-40px);
    display: block;

    @include h.breakpoint-m {
      width: auto;
      margin: unset;
    }
  }
}

:global {
  .j_content_poll {
    font-family: var(--founders-cond);
    font-weight: normal;
    color: var(--grey-50);

    .c-poll,
    .c-poll.c-wide-poll {
      width: auto;
      max-width: unset !important;
      border: none;
      margin: 0 !important;
      padding: 0;

      & p {
        margin: 0;
        padding: 0;
      }
    }

    .c-survey-header {
      display: none;
    }

    .c-poll-question {
      font-size: h.rem(20px);
      line-height: h.rem(25px);
      margin: 0;
      padding: 0;

      @include h.breakpoint-m {
        font-size: h.rem(30px);
        line-height: h.rem(35px);
      }
    }

    // Today
    .today & {
      .j_widget {
        width: 100%;
        position: relative;
        background-color: #fff;
        margin: h.rem(0 0 60px 0);
        padding: h.rem(40px);

        .c-poll-question {
          font-family: var(--publico-hed);
          font-weight: normal;
          color: var(--purple-70);
        }

        .c-vote-count,
        .c-votes-total {
          font-family: var(--founders-mono);
          font-weight: normal;
          font-size: h.rem(12px);
          line-height: 1;
          letter-spacing: -0.5px;
          margin: h.rem(10px 0 0);
          padding: 0;
        }

        .c-poll-choices {
          display: block;
          margin: h.rem(30px 0 0);
        }

        .c-poll-choice {
          font-size: h.rem(17px);
          line-height: 1;
          text-transform: uppercase;
          color: var(--grey-40);
          margin: h.rem(0 0 5px 0);
          padding: h.rem(18px 0 0 0);
          clear: left;
          min-height: h.rem(50px);

          .b-poll-vote-mark {
            background-image: $icon-checkmark-option;
            float: left;
            display: block;
            height: h.rem(50px);
            width: h.rem(50px);
            object-fit: contain;
            margin: h.rem(-18px 20px 0 0);

            @include h.breakpoint-m {
              margin-right: h.rem(30px);
            }
          }

          &.checked {
            color: var(--default-color);

            .b-poll-vote-mark {
              background-image: $icon-checkmark-choice;
            }
          }

          &:not(.checked) {
            &:hover {
              color: var(--default-color);

              .b-poll-vote-mark {
                background-image: none;
                background-color: var(--default-color);
                // stylelint-disable-next-line
                -webkit-mask-image: $icon-checkmark-option;
                mask: $icon-checkmark-option;
              }
            }
          }

          input {
            display: none;
          }
        }

        .c-results {
          a {
            font-family: var(--founders-mono);
            font-weight: normal;
            font-size: h.rem(12px);
            line-height: 1;
            color: var(--default-color);
            text-decoration: none;
            text-transform: capitalize;
            letter-spacing: inherit;
          }
        }

        .c-submit-btn {
          display: block;
          width: 100%;
          height: h.rem(50px);
          margin: h.rem(30px 0 10px);

          .c-vote {
            width: h.rem(240px);
            float: none;
            border: solid 1px var(--default-color);
            background-color: transparent;
            color: var(--default-color);
            margin: 0 auto;
            padding: 0;
            text-transform: uppercase;

            @include h.breakpoint-m {
              width: h.rem(360px);
            }
          }
        }

        .c-full-results {
          margin: 0 auto;
        }

        .c-poll-results {
          [class*='c-bar'] {
            .c-voted {
              &.col0,
              &.col3,
              &.col6,
              &.col9 {
                .c-yesVoted {
                  background: none;
                  background-color: rgba(255, 80, 60, 0.4);
                }
              }

              &.col1,
              &.col4,
              &.col7 {
                .c-yesVoted {
                  background: none;
                  background-color: rgba(255, 80, 60, 0.7);
                }
              }

              &.col2,
              &.col5,
              &.col8 {
                .c-yesVoted {
                  background: none;
                  background-color: rgba(255, 80, 60, 1);
                }
              }
            }
          }

          .c-poll-data {
            .c-percentage {
              font-size: h.rem(20px);
              line-height: 1;
              color: var(--default-color);
              margin: h.rem(20px 0 10px 0);
              padding: 0;
            }

            .c-vote-count {
              font-family: var(--founders-mono);
              font-weight: normal;
              font-size: h.rem(12px);
              line-height: 1;
              color: var(--grey-50);
              margin: 0;
              padding: 0;
            }

            .c-answer {
              font-size: h.rem(14px);
              line-height: 1;
              text-transform: uppercase;
              border: 1px solid #fff;
              color: var(--grey-40);
              margin: h.rem(40px 0 0);
              padding: h.rem(12px 24px);

              &.c-user-voted {
                background: none;
                border: 1px solid var(--default-color);
                color: var(--default-color);
              }
            }
          }
        }

        .c-no-results {
          .c-thanks {
            font-size: h.rem(20px);
            line-height: 1;
            font-family: var(--publico-hed);
            font-weight: normal;
            color: var(--purple-70);
            text-align: center;
            margin: h.rem(48px 0 0);
          }
        }

        &::after {
          border-image: linear-gradient(135deg, var(--default-color), #720a72) 1;
          border-bottom: 1px solid;
          border-right: 1px solid;
          position: absolute;
          height: calc(100% - 25px);
          width: calc(100% - 20px);
          right: 20px;
          bottom: -20px;
          display: block;
          content: ' ';
        }
      }
    }
  }
}
