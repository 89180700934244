@use 'assets/styles/utils/helper' as h;

.sponsoredTip {
  background-color: var(--grey-70);
  margin-left: h.$outer-gutter;
  margin-right: h.$outer-gutter;

  @include h.breakpoint-m {
    max-width: 100%;
    min-height: 365px;
    margin-left: h.$gutter-tablet;
    margin-right: h.$gutter-tablet;
  }

  @include h.breakpoint-l {
    max-width: calc(100% - #{h.$gutter-desktop * 2});
    min-height: 300px;
    margin-right: h.$gutter-desktop;
    margin-left: h.$gutter-desktop;
  }

  @include h.breakpoint-x {
    width: calc(100% + 20px);
    max-width: none;
    min-height: 365px;
    margin-left: h.rem(-60px);
  }

  .image {
    position: relative;

    img {
      margin-left: h.$neg-outer-gutter;

      @include h.breakpoint-m {
        width: 300px;
        margin-left: #{h.$gutter-tablet * -1};
      }

      @include h.breakpoint-l {
        width: 240px;
        margin-left: #{h.$gutter-desktop * -1};
      }

      @include h.breakpoint-x {
        width: 300px;
      }
    }
  }

  .content {
    margin-left: 0;
    padding-bottom: 40px;

    @include h.breakpoint-m {
      margin-top: 1.25rem;
    }

    div {
      border-bottom: 1px solid var(--grey-50);
      width: calc(100% + 32px);

      @include h.breakpoint-m {
        width: calc(100% + 82px);
        margin-left: h.rem(-51px);
      }

      @include h.breakpoint-l {
        width: calc(100% + 32px);
        margin-left: 0;
      }

      @include h.breakpoint-x {
        width: 100%;
        margin-left: 0;
      }
    }

    h3 {
      @include h.breakpoint-m {
        margin-left: 40px;
      }
    }

    p {
      margin-top: 17px;

      @include h.breakpoint-m {
        margin-left: 40px;
      }
    }
  }

  .text {
    font-family: var(--founders-cond);

    :global(body.today) & {
      font-family: var(--secondary-font);
    }
  }
}
