@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss' as t;

$border-styles: solid var(--space-1) var(--related-title-border-color);

.related {
  position: relative;
  margin: var(--related-margin);
  padding: var(--related-padding);
  flex-direction: var(--related-flex-direction);
  clear: both;

  &__title {
    color: var(--related-title-color);
    font-size: var(--related-title-font-size);
    display: var(--related-title-display);
    padding: var(--related-title-padding);
    font-family: var(--related-title-font-family);
    font-style: var(--related-title-font-style);
    font-weight: var(--related-title-font-weight);
    line-height: var(--related-title-leading);
    margin: 0;
    text-transform: var(--related-title-text-transform);
    white-space: nowrap; // title should never wrap to two lines

    &--nbcnews-commerce {
      color: var(--related-title-nbcnews-commerce-color);
      background-color: var(--white);
    }

    &::after {
      content: var(--related-title-after-content);
      height: var(--related-title-after-height);
      margin-left: var(--related-title-after-margin-left);
      background-image: var(--related-title-background-image);
      background-size: var(--related-title-background-size);
      background-repeat: var(--related-title-background-repeat);
      flex: 0 1 auto;
      align-self: center;
      width: 100%;
    }

    // TODO: Remove use of breakpoint-s and design mobile first
    @include h.breakpoint-s-only {
      &::after {
        background-color: var(--related-title-border-color);
      }

      &--nbcnews-commerce::after {
        background-color: var(--related-title-nbcnews-commerce-background-color);
      }
    }

    @include h.breakpoint-m {
      min-width: 240px;
      background-color: var(--related-title-background-color);

      &--trending {
        min-width: auto;
      }

      &--betternews-commerce {
        min-width: 240px;
      }

      &--nbcnews-commerce {
        color: var(--white);
        background-color: var(--related-title-nbcnews-commerce-background-color);
        border: var(--related-title-nbcnews-commerce-border-styles);
      }
    }

    body.today & {
      &--trending {
        font-family: var(--primary-font);
        font-size: h.rem(24px);
        font-weight: normal;
        position: relative;
        color: var(--grey-70);

        @include h.title-underline(var(--accent-blue), h.rem(28px), 1.5);

        @include h.breakpoint-m {
          font-size: h.rem(28px);
        }
      }
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: var(--related-items-padding);
    margin-left: var(--related-items-margin-left);
    flex-direction: var(--related-items-flex-direction);

    @include h.ie {
      max-height: var(--related-items-max-height);
    }

    &--vertical {
      flex-direction: column;
    }

    .related--single & {
      padding: 0;
    }

    // Boxed frame for m & larger
    @include h.breakpoint-m {
      background-color: var(--related-item-background-color);
      position: relative;
      min-height: var(--space-100);

      &::after {
        background-color: var(--related-title-background-color);
        content: var(--related-items-after-content);
        display: block;
        height: calc(100% - var(--space-32));
        left: calc(-1 * var(--space-40));
        position: absolute;
        top: 0;
        width: var(--space-40);
      }

      &--nbcnews-commerce::after {
        background-color: var(--related-title-nbcnews-commerce-background-color);
        max-height: var(--related-title-nbcnews-commerce-background-max-height);
      }

      &--betternews-commerce::after {
        max-height: var(--related-title-betternews-commerce-background-max-height);
      }
    }
  }

  body.today & {
    &__items--shop-today {
      padding-bottom: var(--space-40);
    }

    &--trending,
    &--shop-today {
      .related {
        &__items {
          margin-top: 20px;
        }

        &Item {
          &__title a {
            line-height: 125%;
          }

          &__thumb {
            picture {
              width: 60px;
              height: 60px;

              @include h.breakpoint-m {
                width: 80px;
                height: 80px;
              }
            }
          }
        }
      }
    }

    .related__items--see-more-button {
      margin-top: 0;
    }
  }

  &:not(&--trending):not(&--shop-today):not(&--select-foot) {
    margin: var(--related-non-prefixed-margin);
    margin-left: var(--related-non-prefixed-margin-left);
    width: var(--related-non-prefixed-width);

    body.today & {
      z-index: 0;

      &::before {
        content: '';
        background: var(--grey-20);
        transform: skewY(2deg);
        position: absolute;
        bottom: -30px;
        left: -20px;
        right: -20px;
        top: -20px;
        z-index: -1;

        @include h.breakpoint-m {
          bottom: 0;
          left: -30px;
          right: 0;
          top: -10px;
          transform: skewY(1.22deg);
        }

        @include h.breakpoint-l {
          bottom: -10px;
          left: -40px;
          right: -10px;
          top: -20px;
        }
      }
    }
  }
}

body.select {
  .related--single:not(.related--select-foot) {
    display: block;
    margin: 0 0 8px;
    padding: 0;
    overflow: hidden;

    .related__title {
      background-color: var(--white);
      color: #3e4855;
      display: flex;
      font-size: h.rem(18px);
      line-height: h.rem(22px);
      padding: 0;
      position: relative;
      text-transform: none;

      &::after {
        background-color: transparent;
        background-repeat: repeat;
        bottom: 0;
        color: #2a2a2a;
        content: ' ';
        display: block;
        height: 18px;
        left: 100%;
        margin: 0 0 0 20px;
        position: unset;
        width: 200%;
      }

      @include h.breakpoint-m {
        background-color: #0477c9;
        color: var(--white);
        display: inline-block;
        font-size: h.rem(20px);
        line-height: h.rem(25px);
        padding: var(--space-24) var(--space-40) var(--space-20);

        &::after {
          margin: 0;
          position: absolute;
        }
      }

      @include h.breakpoint-l {
        margin-left: var(--related-non-prefixed-margin-left);
        width: var(--related-non-prefixed-width);
      }
    }

    .related__items {
      margin-left: 0;
      background-color: transparent;
      max-height: initial;
      padding: 0;

      &::after {
        background-color: #0477c9;
      }

      @include h.breakpoint-m {
        margin-left: var(--space-40);
        background-color: var(--grey-20);
      }
    }

    .related-item {
      border-top: 1px solid #ccc;
      margin-top: 20px;
      padding-top: 20px;

      &__no-art {
        margin: 20px 20px 0 0;
        border-top: 1px solid #ccc;
        width: calc(100% - 40px);

        .related-item__eyebrow {
          display: block;
        }

        .related-item__info-wrapper {
          padding-left: 0;
        }
      }

      @include h.breakpoint-m {
        border-top: none;
        margin-top: 0;
        padding-top: 0;

        &__no-art {
          margin: 20px;
        }
      }
    }
  }

  .related--select-foot {
    display: flex;
    margin: 0;

    & .related__title { //stylelint-disable-line
      padding-top: 0;
    }

    @include h.breakpoint-l {
      margin-top: unset;
      display: none;
    }
  }
}
