@use 'assets/styles/utils/helper' as h;

:root {
  --gift-guide-wrapper-margin: var(--space-8) 0;
  --gift-guide-inner-padding: var(--space-8) 0;
  --gift-guide-title-font-family: var(--default-headline-font-family);
  --gift-guide-title-color: var(--default-color);
  --gift-guide-title-font-style: italic;
  --gift-guide-title-padding: 0;
  --gift-guide-title-background: none;
  --gift-guide-thumbnail-margin: 0;
  --gift-guide-thumbnail-hover-transform: none;
  --gift-guide-img-border-radius: 50%;
  --gift-guide-item-title-color: var(--default-headline-color);
  --gift-guide-item-title-font-family: var(--default-headline-font-family);
  --gift-guide-item-title-hover-border: none;
  --gift-guide-item-title-margin: var(--space-8) 0 0;
  --gift-guide-item-divider: linear-gradient(to top, var(--grey-60) 10%, transparent 20%);
  --gift-guide-divider-right: -19px;
  --gift-guide-items-gap: 30px;

  @include h.breakpoint-m {
    --gift-guide-items-gap: 36px;
  }

  @include h.breakpoint-l {
    --gift-guide-items-gap: 60px;
    --gift-guide-divider-right: -30px;
  }

  @include h.breakpoint-x {
    --gift-guide-items-gap: 96px;
    --gift-guide-divider-right: -48px;
  }
}

:global(body.today) {
  --gift-guide-title-font-family: var(--primary-font);
  --gift-guide-title-color: var(--grey-70);
  --gift-guide-title-font-style: normal;
  --gift-guide-title-padding: #{h.rem(11px 0 5px)};
  --gift-guide-title-background: url('~assets/images/today/giftguide-mobile.svg') no-repeat top center / 324px 63px;
  --gift-guide-thumbnail-margin: #{h.rem(20px)};
  --gift-guide-thumbnail-hover-transform: rotate(-2deg);
  --gift-guide-img-border-radius: none;
  --gift-guide-item-title-color: var(--grey-70);
  --gift-guide-item-title-font-family: var(--secondary-font);
  --gift-guide-item-title-hover-border: 2px solid var(--accent-orange);
  --gift-guide-item-title-margin: 0;
  --gift-guide-item-divider: linear-gradient(to top, var(--grey-30) 100%, transparent 0%);
  --gift-guide-items-gap: 40px 30px;

  @include h.breakpoint-m {
    --gift-guide-items-gap: 36px;
    --gift-guide-title-padding: #{h.rem(20px 0 10px)};
    --gift-guide-title-background: url('~assets/images/today/giftguide-desktop.svg') no-repeat top center / 525px 101px;
  }

  @include h.breakpoint-l {
    --gift-guide-items-gap: 70px;
    --gift-guide-divider-right: -35px;
  }

  @include h.breakpoint-x {
    --gift-guide-items-gap: 136px;
    --gift-guide-divider-right: -68px;
  }
}

:global(body.today.articlePage) {
  --gift-guide-wrapper-margin: 0;

  @include h.breakpoint-m {
    --gift-guide-wrapper-margin: #{h.rem(64px 0 48px)};
  }

  @include h.breakpoint-l {
    --gift-guide-wrapper-margin: #{h.rem(24px 0 48px)};
    --gift-guide-inner-padding: var(--space-8) 0;
  }
}
