@use 'assets/styles/utils/helper' as h;

:root {
  --menu-embed-overflow: initial;
  --menu-embed-width: calc(100% + var(--space-100));
  --menu-embed-margin: calc(-1 * var(--space-100));
  --menu-title-display: flex;
  --menu-title-background-color: initial;
  --menu-title-color: var(--default-color);
  --menu-title-font-family: var(--default-headline-font-family);
  --menu-title-font-style: normal;
  --menu-title-font-size: var(--text-20);
  --menu-title-font-weight: var(--semibold);
  --menu-title-leading: var(--leading-100);
  --menu-title-margin: 0 0 10px;
  --menu-title-min-width: 345px;
  --menu-title-padding: var(--space-24) var(--space-40) var(--space-16);
  --menu-title-after-content: '';
  --menu-title-after-background-color: var(--default-color);
  --menu-title-after-background-gradient: none;
  --menu-title-after-align-self: center;
  --menu-title-after-height: var(--space-1);
  --menu-title-after-background-repeat: initial;
  --menu-title-after-width: 100%;
  --menu-title-after-background-size: auto;
  --menu-title-after-margin-left: var(--space-12);
  --menu-title-after-top: initial;
  --menu-title-after-right: initial;
  --menu-title-after-left: initial;
  --menu-title-after-bottom: initial;
  --menu-title-border: none;
  --menu-title-after-display: block;
  --menu-items-background-color: var(--neutral-10);
  --menu-items-margin-left: var(--space-40);
  --menu-items-padding: var(--space-20) 0;
  --menu-items-font-weight: var(--semibold);
  --menu-items-font-family: var(--default-headline-font-family);
  --menu-items-font-style: var(--normal);
  --menu-items-color: var(--default-text-color);
  --menu-items-font-size: var(--text-18);
  --menu-items-line-height: 1;
  --menu-items-spacing: var(--space-16);
  --menu-items-see-more-border-radius: initial;
  --menu-items-after-height: var(--space-40);
  --menu-items-after-display: block;
  --menu-items-border: 1px solid #ccc;
  --menu-items-hover-opacity: var(--default-hover-opacity);
  --menu-items-hover-color: var(--menu-items-color);

  @include h.breakpoint-m {
    --menu-title-after-display: none;
    --menu-title-display: inline-block;
    --menu-title-background-color: var(--default-color);
    --menu-title-color: var(--white);
    --menu-title-font-size: var(--text-24);
    --menu-title-margin: 0;
    --menu-title-after-content: none;
    --menu-items-font-size: var(--text-20);
    --menu-items-padding: var(--space-40);
    --menu-title-after-background-color: transparent;
  }
}

body.today {
  --menu-title-after-background-color: var(--default-color);
  --menu-title-min-width: none;
  --menu-title-padding: var(--space-20) var(--space-40);
  --menu-items-background-color: var(--white);
  --menu-title-border: var(--space-1) solid var(--default-color);
  --menu-title-after-display: block;
  --menu-title-after-content: '';
  --menu-title-after-align-self: center;
  --menu-title-after-height: var(--space-1);
  --menu-title-after-width: 100%;
  --menu-title-after-top: 0;
  --menu-title-after-right: 0;
  --menu-items-after-height: 33%;
  --menu-embed-width: 100%;
  --menu-embed-margin: 0;
  --menu-items-after-display: none;
  --menu-title-background-color: transparent;
  --menu-title-font-family: var(--primary-font);
  --menu-title-font-size: #{h.rem(24px)};
  --menu-title-font-weight: normal;
  --menu-title-leading: 1.5;
  --menu-title-font-style: normal;
  --menu-title-color: var(--grey-60);
  --menu-items-border: none;
  --menu-items-color: var(--grey-70);
  --menu-items-font-size: #{h.rem(16px)};
  --menu-items-font-family: var(--secondary-font);
  --menu-items-font-weight: normal;
  --menu-items-line-height: 1.5;
  --menu-items-padding: 10px 0 20px;
  --menu-items-margin-left: 0;
  --menu-items-spacing: #{h.rem(12px)};
  --menu-items-hover-opacity: 1;
  --menu-items-hover-color: var(--primary-color);

  @include h.breakpoint-m {
    --menu-title-after-background-color: var(--default-neutral);
    --menu-title-after-align-self: initial;
    --menu-title-after-height: 50%;
    --menu-title-after-width: var(--space-16);
    --menu-title-font-size: #{h.rem(28px)};
    --menu-items-padding: 15px 0 30px;
    --menu-items-font-size: #{h.rem(20px)};
  }
}

body.think {
  --menu-title-font-family: var(--founders-cond);
  --menu-items-font-size: var(--text-14);
  --menu-items-line-height: 1.25;
  --menu-items-font-weight: var(--light);

  @include h.breakpoint-m {
    --menu-items-spacing: var(--space-20);
    --menu-items-font-size: var(--text-16);
  }
}

body.select {
  --menu-title-after-content: '';
  --menu-title-font-family: var(--lato);
  --menu-title-font-size: #{h.rem(18px)};
  --menu-title-leading: #{h.rem(21.6px)};
  --menu-title-background-color: var(--white);
  --menu-title-font-weight: 900;
  --menu-title-padding: var(--space-20) var(--space-40);
  --menu-title-margin: 0 0 20px;
  --menu-title-color: #3e4855;
  --menu-items-font-size: #{h.rem(14px)};
  --menu-items-line-height: 120%;
  --menu-items-font-weight: 700;
  --menu-items-color: #2a2a2a;
  --menu-title-after-display: block;
  --menu-title-after-width: 200%;
  --menu-title-after-background-gradient: var(--default-background-image);
  --menu-title-after-background-color: transparent;
  --menu-title-after-height: 18px;
  --menu-title-after-background-repeat: repeat-x;
  --menu-title-after-background-size: 18px;
  --menu-title-after-left: 100%;
  --menu-title-after-bottom: 0;
  --menu-title-after-margin-left: var(--space-12);
  --menu-embed-overflow: hidden;
  --menu-items-see-more-border-radius: 100px;
  --menu-items-after-height: 33%;

  @include h.breakpoint-m {
    --menu-title-font-size: #{h.rem(20px)};
    --menu-title-leading: #{h.rem(25px)};
    --menu-title-background-color: var(--blue-60);
    --menu-title-margin: 0;
    --menu-title-color: var(--white);
    --menu-items-font-size: #{h.rem(16px)};
    --menu-title-after-margin-left: 0;
  }
}
