@use 'assets/styles/utils/helper' as h;

.nl-signup-input {
  --nl-signup-input--padding: 0;
  --signup-input-design-color: var(--default-color);
  --nl-signup-input--input--background-color: transparent;
  --nl-signup-input--input--border-color: var(--grey-40);
  --nl-signup-input--input--border-style: solid;
  --nl-signup-input--input--border-bottom-width: 0;
  --nl-signup-input--input--border-top: 0;
  --nl-signup-input--input--border-right: 0;
  --nl-signup-input--input--border-left: 0;
  --nl-signup-input--input--color: inherit;
  --nl-signup-input--input--font-family: var(--publico-hed);
  --nl-signup-input--input--font-size: inherit;
  --nl-signup-input--input--line-height: inherit;
  --nl-signup-input--input--margin: 0;
  --nl-signup-input--input--outline: 0;
  --nl-signup-input--input--height: auto;
  --nl-signup-input--input--padding: #{h.rem(12px 0 12px 12px)};
  --nl-signup-input--input-placeholder--color: var(--grey-50);
  --nl-signup-input--input-focus--border-color: var(--grey-40);
  --nl-signup-input--input-invalid--background-color: #fff8da;
  --nl-signup-input--input-invalid--border-color: #c9234a;
  --nl-signup-input--button--background-color: var(--white);
  --nl-signup-input--button--border-color: var(--default-color);
  --nl-signup-input--button--border-radius: 0;
  --nl-signup-input--button--border-style: solid;
  --nl-signup-input--button--border-width: 1px;
  --nl-signup-input--button--color: var(--default-color);
  --nl-signup-input--button--font-family: var(--founders-cond);
  --nl-signup-input--button--font-size: var(--text-16);
  --nl-signup-input--button--font-weight: normal;
  --nl-signup-input--button--line-height: 1;
  --nl-signup-input--button--margin: 0;
  --nl-signup-input--button--max-width: #{h.rem(207px)};
  --nl-signup-input--button--min-width: #{h.rem(83px)};
  --nl-signup-input--button--padding: 0;
  --nl-signup-input--button--text-transform: uppercase;
  --nl-signup-input--button--width: 30%;
  --nl-signup-input--button--hover--opacity: 1;
  --nl-signup-input--button--active--opacity: 1;
  --nl-signup-input--recaptcha-links--display: block;
  --nl-signup-input--recaptcha-links--margin: 0;
  --nl-signup-input--recaptcha-link--color: var(--default-color);
  --nl-signup-input--recaptcha-link--margin: 0;
  --nl-signup-input--recaptcha-link--border-bottom: none;
  --nl-signup-input--recaptcha-link--hover-opacity: var(--default-hover-opacity);
  --nl-signup-input--recaptcha-link--active-opacity: var(--default-active-opacity);
  --nl-signup-input--recaptcha-link--hover-color: var(--default-color);
  --nl-signup-input--footer--font-family: var(--founders-mono);
  --nl-signup-input--footer--font-size: var(--text-10);
  --nl-signup-input--footer--font-style: normal;
  --nl-signup-input--footer--font-weight: normal;
  --nl-signup-input--footer--line-height: 1;
  --nl-signup-input--footer--letter-spacing: -0.04em;
  --nl-signup-input--footer--text-transform: uppercase;
  --nl-signup-input--footer--margin: 16px 0 0;
  --nl-signup-input--footer--color: inherit;
  --nl-signup-input--submitted-message--border-color: var(--grey-40);
  --nl-signup-input--submitted-message--border-style: solid;
  --nl-signup-input--submitted-message--border-width: 0;
  --nl-signup-input--submitted-message--color: var(--grey-60);
  --nl-signup-input--submitted-message--padding: 12px 0;

  @include h.breakpoint-m {
    --nl-signup-input--recaptcha-links--display: inline;
    --nl-signup-input--footer--margin: 24px 0 0;
  }

  @include h.breakpoint-l {
    --nl-signup-input--footer--margin: 32px 0 0;
  }

  body.better & {
    --nl-signup-input--input--border-bottom-width: 1px;
    --nl-signup-input--submitted-message--border-width: 0 0 1px 0;
  }

  body.msnbc & {
    --nl-signup-input--input--border-bottom-width: 1px;
    --nl-signup-input--submitted-message--border-width: 0 0 1px 0;
  }

  body.news & {
    --nl-signup-input--input--border-bottom-width: 1px;
    --nl-signup-input--submitted-message--border-width: 0 0 1px 0;
  }

  body.noticias & {
    --nl-signup-input--input--border-bottom-width: 1px;
    --nl-signup-input--submitted-message--color: var(--grey-70);
    --nl-signup-input--submitted-message--border-color: var(--grey-70);
    --nl-signup-input--submitted-message--border-width: 0 0 1px 0;
  }

  body.select & {
    --nl-signup-input--input--border-color: #ccc;
    --nl-signup-input--input--border-bottom-width: 1px;
    --nl-signup-input--input--color: var(--black);
    --nl-signup-input--input--font-family: var(--lato);
    --nl-signup-input--input--font-size: var(--text-20);
    --nl-signup-input--input--line-height: 25px;
    --nl-signup-input--input--padding: 9px 20px 9px 12px;
    --nl-signup-input--button--padding: 12px 16px;
    --nl-signup-input--input-focus--border-color: var(--blue-60);
    --nl-signup-input--input-placeholder--color: #999;
    --nl-signup-input--button--background-color: var(--blue-60);
    --nl-signup-input--button--border-radius: 22px;
    --nl-signup-input--button--border-width: 0;
    --nl-signup-input--button--color: var(--white);
    --nl-signup-input--button--font-family: var(--lato);
    --nl-signup-input--button--font-size: var(--text-16);
    --nl-signup-input--button--font-weight: 700;
    --nl-signup-input--button--margin: 0 0 0 -18px;
    --nl-signup-input--button--max-width: unset;
    --nl-signup-input--button--min-width: unset;
    --nl-signup-input--button--text-transform: initial;
    --nl-signup-input--button--width: auto;
    --nl-signup-input--button--hover--opacity: var(--default-hover-opacity);
    --nl-signup-input--button--active--opacity: var(--default-active-opacity);
    --nl-signup-input--footer--font-family: var(--default-text-font-family);
    --nl-signup-input--footer--line-height: 1.5;
    --nl-signup-input--footer--letter-spacing: 0.5px;
    --nl-signup-input--footer--margin: 22px 0 0;
    --nl-signup-input--submitted-message--border-width: 0;
    --nl-signup-input--submitted-message--color: var(--black);
    --nl-signup-input--submitted-message--padding: 12px 0;

    @include h.breakpoint-m {
      --nl-signup-input--input--padding: 9px 20px;
      --nl-signup-input--button--padding: 12px 40px;
    }
  }

  body.think & {
    --nl-signup-input--input--border-bottom-width: 1px;
    --nl-signup-input--submitted-message--border-width: 0 0 1px 0;
  }

  body.today & {
    --nl-signup-input--input--border-bottom-width: 1px;
    --nl-signup-input--input--border-top: 1px solid var(--grey-40);
    --nl-signup-input--input--border-right: 1px solid var(--grey-40);
    --nl-signup-input--input--border-left: 1px solid var(--grey-40);
    --nl-signup-input--button--border: 0;
    --nl-signup-input--input--background-color: var(--white);
    --nl-signup-input--input--color: var(--grey-80);
    --nl-signup-input--input--font-family: var(--secondary-font);
    --nl-signup-input--input--padding: 15px;
    --nl-signup-input--input--font-size: var(--text-14);
    --nl-signup-input--input--margin: 0.5rem 0 0;
    --nl-signup-input--input--height: 44px;
    --nl-signup-input--input-placeholder--color: var(--grey-60);
    --nl-signup-input--footer--font-family: var(--secondary-font);
    --nl-signup-input--footer--font-weight: 400;
    --nl-signup-input--footer--font-size: 10px;
    --nl-signup-input--footer--line-height: 150%;
    --nl-signup-input--footer--letter-spacing: 0;
    --nl-signup-input--footer--text-transform: none;
    --nl-signup-input--footer--margin: #{h.rem(11px)} 0 0;
    --nl-signup-input--footer--color: var(--grey-50);
    --nl-signup-input--recaptcha-links--margin: 0 0 0 #{h.rem(10px)};
    --nl-signup-input--recaptcha-link--color: var(--grey-60);
    --nl-signup-input--recaptcha-link--margin: 0 2px;
    --nl-signup-input--recaptcha-link--border-bottom: solid 2px var(--accent-orange);
    --nl-signup-input--recaptcha-link--hover-opacity: 1;
    --nl-signup-input--recaptcha-link--active-opacity: 1;
    --nl-signup-input--recaptcha-link--hover-color: var(--orange-30);
  }

  body.today.newsletterEmbedPage & {
    --nl-signup-input--padding: 0 0 0 #{h.rem(5px)};
  }
}
