@use 'assets/styles/utils/helper' as h;

.embed-widget {
  --embed-widget-border-color: #666;
  --embed-widget-border-top-color: var(--default-color);

  body.news & {
    --embed-widget-border-top-color: var(--blue-70);
  }

  body.noticias & {
    --embed-widget-border-top-color: var(--blue-60);
  }
}
