@use 'assets/styles/utils/helper' as h;

.card {
  margin-top: h.rem(60px);
  margin-bottom: h.rem(60px);
  padding-right: h.rem(50px);
  position: relative;

  @include h.breakpoint-l {
    padding-right: 0;
    margin-left: 0;
    clear: left;
  }

  @include h.breakpoint-x {
    width: calc(100% + 100px);
    margin-left: h.rem(-100px);
  }
}

.coverImage {
  display: block;
  position: relative;

  > img {
    height: 100%;
  }

  &::after {
    content: '';
    border-right: 2px solid var(--primary-color);
    height: h.rem(80px);
    position: absolute;
    right: -6px;
    bottom: 8px;

    @include h.breakpoint-m {
      border-right: 3px solid var(--primary-color);
      height: h.rem(187px);
      right: -10px;
      bottom: 20px;
    }
  }
}

.imageWrapper {
  display: flex;
  position: relative;
  z-index: 2;
  transition: transform 0.1s ease-in-out;

  @include h.breakpoint-l {
    margin-right: h.rem(40px);
  }

  @include h.breakpoint-x {
    margin-right: 0;
  }
}

.byline {
  position: absolute;
  bottom: -17px;
  right: 0;
  z-index: 2;
  color: var(--grey-50);
  text-align: right;
  line-height: 1.5;
  font-size: h.rem(10px);
  font-family: var(--secondary-font);

  @include h.breakpoint-m {
    font-size: h.rem(12px);
    bottom: -22px;
  }
}

.image-byline-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: h.rem(20px 0);

  .image {
    width: 100%;
    max-width: h.rem(300px);
    margin-bottom: h.rem(10px);
  }

  .byline {
    font-size: h.rem(14px);
    color: var(--text-color);
  }
}

.txtWrapper {
  font-family: var(--secondary-font);
  line-height: 1.5;
  color: var(--grey-60);
  padding: h.rem(40px 70px 30px 20px);
  margin-left: h.rem(-20px);
  margin-right: h.rem(-70px);
  background: var(--neutral-yellow);
  clip-path: polygon(0 0, 94% 0, 100% 100%, 0% 100%);
  margin-top: h.rem(-30px);
  position: relative;
  z-index: 1;

  @include h.breakpoint-m {
    padding: h.rem(70px 90px 60px 30px);
    margin-top: h.rem(-45px);
    margin-left: h.rem(-30px);
    margin-right: h.rem(-90px);
  }

  @include h.breakpoint-l {
    padding: h.rem(70px 30px 60px 40px);
    margin-left: h.rem(-40px);
    margin-right: h.rem(-20px);
  }

  @include h.breakpoint-x {
    padding: h.rem(70px 78px 60px 40px);
    margin-right: h.rem(-78px);
  }

  &.withDek {
    padding-bottom: 40px;
    background: var(--neutral-orange);
  }

  &.noImg {
    padding-top: 30px;
    margin-top: 0;

    @include h.breakpoint-m {
      padding-top: 40px;
    }
  }
}

.photos {
  font-weight: normal;
  color: var(--grey-50);
  text-transform: lowercase;
}

.title {
  font-weight: normal;
  font-size: h.rem(20px);
  margin-top: 0;
  margin-bottom: h.rem(10px);

  @include h.breakpoint-m {
    font-size: h.rem(24px);
    width: 100%;
  }

  a:hover {
    opacity: 1;
    border-bottom: 2px solid var(--accent-orange);
  }
}

.author {
  font-size: h.rem(12px);
}

.description {
  font-size: h.rem(14px);

  @include h.breakpoint-m {
    font-size: h.rem(16px);
  }
}

.tiltWrapper {
  &:hover {
    transform: rotate(1deg);
  }
}

.card:has(.tiltWrapper:hover, .title a:hover) {
  .tiltWrapper {
    transform: rotate(1deg);
  }

  .title a {
    opacity: 1;
    border-bottom: 2px solid var(--accent-orange);
  }
}

.save {
  border-bottom-color: var(--neutral-yellow) !important;
  margin-left: h.rem(-3px);
}
