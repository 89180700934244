@use 'assets/styles/utils/helper' as h;
@use 'theme.tokens' as t;

.blockquote {
  border-left: var(--blockquote--border-left-width) var(--blockquote--border-style) var(--blockquote--border-color);
  color: var(--blockquote--color);
  font-family: var(--blockquote--font-family);
  font-size: var(--blockquote--font-size);
  line-height: var(--blockquote--line-height);
  position: relative;
  width: var(--blockquote--width);
  margin: var(--blockquote--margin);
}

.blockquote__quote {
  font-style: var(--blockquote--quote--font-style);
  margin: var(--blockquote--quote--margin);
  padding: var(--blockquote--quote--padding);

  p {
    margin: var(--blockquote--quote-text-margin);
  }
}

.blockquote__quote::before {
  mask-image: var(--blockquote--quote-before--mask-image);
  mask-repeat: var(--blockquote--quote-before--mask-repeat);
  mask-size: var(--blockquote--quote-before--mask-size);
  background-color: var(--blockquote--quote-before--background-color);
  content: var(--blockquote--quote-before--content);
  display: var(--blockquote--quote-before--display);
  height: var(--blockquote--quote-before--height);
  left: var(--blockquote--quote-before--left);
  position: var(--blockquote--quote-before--position);
  top: var(--blockquote--quote-before--top);
  width: var(--blockquote--quote-before--width);
}

.blockquote__source {
  color: var(--blockquote--source--color);
  display: var(--blockquote--source--display);
  font-family: var(--blockquote--source--font-family);
  font-size: var(--blockquote--source--font-size);
  font-style: var(--blockquote--source--font-style);
  font-weight: var(--blockquote--source--font-weight);
  letter-spacing: var(--blockquote--source--letter-spacing);
  line-height: var(--blockquote--source--line-height);
  text-transform: var(--blockquote--source--text-transform);
  margin: var(--blockquote--source--margin);

  a {
    text-decoration: var(--blockquote--source--link--text-decoration);
    text-decoration-thickness: var(--blockquote--source--link--text-decoration-thickness);
    text-decoration-color: var(--blockquote--source--link--text-decoration-color);
    text-underline-offset: var(--blockquote--source--link--text-underline-offset);

    &:hover {
      color: var(--blockquote--source--link--hover-color);
    }
  }
}
