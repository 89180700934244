@use 'assets/styles/utils/helper' as h;

.prosConsContainer {
  display: flex;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 48px;
  color: #2a2a2a;
  font-family: var(--listicle-product-pros-cons-font-family);
  font-size: var(--listicle-product-pros-cons-font-size);
  line-height: var(--listicle-product-pros-cons-font-line-height);

  &.isOde {
    @include h.breakpoint-l {
      max-width: 560px;
    }
  }

  @media (max-width: 758px) {
    flex-direction: column;

    body.select & {
      width: 335px;
      margin-left: -17px;
    }
  }
}

.prosConsChild {
  border: 1px solid var(--listicle-product-pros-cons-background);
  margin-right: -1px;
  width: 50%;

  @media (max-width: 758px) {
    width: 100%;
  }
}

.prosConsLabel {
  background-color: var(--listicle-product-pros-cons-background);
  font-weight: 700;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 20px;
}

.prosConsItems {
  padding: 12px 20px;
  list-style: disc;
  list-style-position: inside;
  font-weight: 400;
  font-family: var(--listicle-product-pros-cons-font-family-item);

  & a {
    color: var(--listicle-product-pros-cons-link-color);
    border-bottom: var(--listicle-product-pros-cons-link-border-bottom);
  }
}

.prosConsFallback {
  padding: 12px 20px;
  font-weight: 400;
  font-family: var(--listicle-product-pros-cons-font-family-item);
  font-style: italic;
}
