@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

$className: summary-box;

.#{$className} {
  background-color: var(--article-summarybox-border-shadow-color);
  padding: var(--article-summarybox-padding);
  margin-top: var(--article-summarybox-margin-top);
  margin-bottom: var(--article-summarybox-margin-bottom);
  width: 100%;

  &__content {
    padding: var(--article-summarybox-content-padding);
    background-color: var(--white);
    border: var(--article-summarybox-border-color) var(--article-summarybox-border-size) solid;
  }

  &__headline {
    color: var(--article-summarybox-headline-font-color);
    font-family: var(--article-summarybox-headline-font-family);
    font-weight: var(--article-summarybox-headline-font-weight);
    margin-top: 0;
    margin-bottom: 16px;
    font-size: var(--article-summarybox-headline-font-size);
    line-height: var(--article-summarybox-headline-line-height);

    body.today & {
      @include h.title-underline(
        var(--accent-yellow),
        var(--article-summarybox-headline-font-size),
        var(--article-summarybox-headline-line-height)
      );
    }
  }

  &__items {
    list-style-type: disc;
    margin-top: var(--article-summarybox-items-margin);

    li {
      display: list-item;
      margin-left: var(--article-summarybox-items-margin-left);
      padding-left: var(--article-summarybox-items-padding-left);
      color: var(--article-summarybox-items-font-color);
      margin-bottom: var(--article-summarybox-items-margin-bottom);
      font-size: var(--article-summarybox-items-font-size);
      position: relative;

      &::marker {
        color: var(--article-summarybox-vertical-color);
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      body.today & {
        list-style: none;

        &::before {
          color: var(--article-summarybox-vertical-color);
          background-repeat: no-repeat;
          content: '\2022';
          display: block;
          position: absolute;
          font-size: h.rem(32px);
          line-height: 65%;
          top: 3px;
          left: 0;
        }
      }

      a {
        color: var(--article-summarybox-items-link-color);

        body.today & {
          border-bottom:
            var(--article-summarybox-items-underline-width)
            solid
            var(--article-summarybox-items-underline-color);
          padding-bottom: var(--article-summarybox-items-underline-padding);
        }
      }

      a:hover {
        color: var(--article-summarybox-items-link-hover-color);
        opacity: var(--article-summarybox-items-hover-opacity);
      }

      a:active {
        opacity: var(--article-summarybox-items-active-opacity);
      }
    }
  }

  // Breaking News Articles & Specific LiveBlogs have stripes NOT in their vertical brand color
  .breaking &,
  .news .is-live-blog &,
  .msnbc .is-live-blog &,
  .noticias .is-live-blog & {
    background:
      repeating-linear-gradient(
        -45deg,
        var(--article-summarybox-breaking-red),
        var(--article-summarybox-breaking-red) 2px,
        transparent 0,
        transparent 4px
      );

    &__content {
      border-color: var(--article-summarybox-breaking-red);
    }

    &__items li::marker {
      color: var(--article-summarybox-breaking-red);
    }
  }

  // LiveBlogs have a stripped background
  .is-live-blog & {
    background:
      repeating-linear-gradient(
        -45deg,
        var(--article-summarybox-border-stripe-color),
        var(--article-summarybox-border-stripe-color) 2px,
        transparent 0,
        transparent 4px
      );

    &__content {
      border: var(--article-summarybox-border);
    }

    &__items li::marker {
      color: var(--article-summarybox-vertical-color);

      body.select & {
        color: var(--article-summarybox-border-stripe-color);
      }
    }
  }
}
