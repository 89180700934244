@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.showMoreButtonWrapper .showMoreButton {
  background-color: inherit;
  height: h.rem(32px);
  width: h.rem(360px);
  padding: 0;

  :global(.select) & {
    border-radius: 100px;
  }

  &:hover {
    color: var(--button-text-hover-color);
    border: 1px solid var(--button-border-hover-color);
  }

  &:focus-visible {
    outline: 3px solid #0db14b;
    outline-offset: 3px;
    z-index: 2;
  }
}

.showMoreButtonWrapper {
  display: flex;
  justify-content: var(--show-more-button-wrapper-justify-content);
  position: relative;

  :global(.select) & {
    margin-top: 15px;
  }

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    min-height: 75px;
    background-image: linear-gradient(to bottom, var(--last-body-item-background-gradient), var(--last-body-item-background-gradient-base));
    position: absolute;
    bottom: var(--show-more-button-wrapper-before-bottom);
    left: 0;
    z-index: 2;
  }

  .showMoreButtonTilt {
    margin-top: 0;
    position: relative;
    z-index: 3;
  }
}
