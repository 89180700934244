@use 'assets/styles/utils/helper' as h;

.nl-signup-inline {
  --nl-signup-header--color: inherit;
  --nl-signup-container--border-color: var(--grey-40);
  --nl-signup-container--border-style: solid;
  --nl-signup-container--border-width: 0;
  --nl-signup-container--before--background-color: transparent;
  --nl-signup-container--before--content: none;
  --nl-signup-container--before--height: none;
  --nl-signup-container--before--left: initial;
  --nl-signup-container--before--position: relative;
  --nl-signup-container--before--top: initial;
  --nl-signup-container--before--width: none;
  --nl-signup-container--margin: #{h.rem(48px 0)};
  --nl-signup-container--title--color: var(--black);
  --nl-signup-container--title--display: block;
  --nl-signup-container--title--font-family:
    var(
      --default-headline-font-family
    );
  --nl-signup-container--title--font-size: #{h.rem(24px)};
  --nl-signup-container--title--font-style: normal;
  --nl-signup-container--title--font-weight: normal;
  --nl-signup-container--title--line-height: 1;
  --nl-signup-container--title--margin: #{h.rem(12px)} 0 0;
  --nl-signup-container--title--after--background-image: none;
  --nl-signup-container--title--after--margin: none;
  --nl-signup-container--title--after--content: none;
  --nl-signup-container--title--after--flex: unset;
  --nl-signup-container--title--after--height: auto;
  --nl-signup-container--description--color: var(--grey-60);
  --nl-signup-container--description--font-family: var(--default-text-font-family);
  --nl-signup-container--description--font-size: #{h.rem(14px)};
  --nl-signup-container--description--line-height: 1.5;

  @include h.breakpoint-s-only { --nl-signup-container--description--margin: #{h.rem(12px 0 20px)}; }

  @include h.breakpoint-m { --nl-signup-container--description--margin: #{h.rem(12px 96px 40px 0)}; }

  --signup-input-design-color: var(--default-color);

  body.better & {
    --nl-signup-header--color: var(--default-color);
    --nl-signup-container--border-width: 1px 0 0;
    --nl-signup-container--before--background-color: var(--default-color);
    --nl-signup-container--before--content: '';
    --nl-signup-container--before--height: 0.5rem;
    --nl-signup-container--before--left: 0;
    --nl-signup-container--before--position: absolute;
    --nl-signup-container--before--top: 0;
    --nl-signup-container--before--width: 5rem;
  }

  body.msnbc & {
    --nl-signup-header--color: var(--default-color);
    --nl-signup-container--border-width: 1px 0 0;
    --nl-signup-container--before--background-color: #{h.$msnbc-yellow-40};
    --nl-signup-container--before--content: '';
    --nl-signup-container--before--height: 0.5rem;
    --nl-signup-container--before--left: 0;
    --nl-signup-container--before--position: absolute;
    --nl-signup-container--before--top: 0;
    --nl-signup-container--before--width: 5rem;
  }

  body.news & {
    --nl-signup-header--color: var(--default-color);
    --nl-signup-container--with-picture--background-color: #f8f8f8;
    --nl-signup-container--border-width: 1px 0 0;
    --nl-signup-container--before--background-color: var(--default-color);
    --nl-signup-container--before--content: '';
    --nl-signup-container--before--height: 0.5rem;
    --nl-signup-container--before--left: 0;
    --nl-signup-container--before--position: absolute;
    --nl-signup-container--before--top: 0;
    --nl-signup-container--before--width: 5rem;
  }

  body.noticias & {
    --nl-signup-header--color: var(--default-color);
    --nl-signup-container--border-width: 1px 0 0;
    --nl-signup-container--before--background-color: var(--default-color);
    --nl-signup-container--before--content: '';
    --nl-signup-container--before--height: 0.5rem;
    --nl-signup-container--before--left: 0;
    --nl-signup-container--before--position: absolute;
    --nl-signup-container--before--top: 0;
    --nl-signup-container--before--width: 5rem;
  }

  body.select & {
    --nl-signup-header--color: var(--default-color);
    --nl-signup-container--title--color: var(--grey-70);
    --nl-signup-container--title--font-size: #{h.rem(18px)};
    --nl-signup-container--title--font-weight: 900;
    --nl-signup-container--title--line-height: 1.2;
    --nl-signup-container--title--display: flex;
    --nl-signup-container--title--margin: #{h.rem(12px)} 0;
    --nl-signup-container--title--after--background-image: var(--default-background-image);
    --nl-signup-container--title--after--content: '';
    --nl-signup-container--title--after--flex: 1;
    --nl-signup-container--title--after--height: #{h.rem(18px)};
    --nl-signup-container--title--after--margin: 0 0 0 #{h.rem(12px)};
    --nl-signup-container--description--color: var(--grey-70);
    --nl-signup-container--description--line-height: #{h.rem(20px)};
    --nl-signup-container--description--margin: #{h.rem(12px 0 20px)};
  }

  body.think & {
    --nl-signup-header--color: var(--default-color);
    --nl-signup-container--border-width: 1px 0 0;
    --nl-signup-container--before--background-color: var(--default-color);
    --nl-signup-container--before--content: '';
    --nl-signup-container--before--height: 0.5rem;
    --nl-signup-container--before--left: 0;
    --nl-signup-container--before--position: absolute;
    --nl-signup-container--before--top: 0;
    --nl-signup-container--before--width: 5rem;
    --nl-signup-container--title--font-family: var(--founders-cond);
  }

  body.today & {
    --nl-signup-header--color: var(--default-color);
    --nl-signup-container--title--color: var(--grey-70);
    --nl-signup-container--title--font-family: var(--secondary-font);
    --nl-signup-container--title--font-style: normal;
    --nl-signup-container--title--font-weight: 700;
    --nl-signup-container--title--font-size: #{h.rem(18px)};
    --nl-signup-container--title--line-height: 150%;
    --nl-signup-container--description--margin: #{h.rem(0.5px)} 0 #{h.rem(1.5px)};
    --nl-signup-container--description--font-family: var(--secondary-font);
    --nl-signup-container--description--font-size: var(--text-14);

    @include h.breakpoint-m {
      --nl-signup-container--title--font-size: var(--text-24);
      --nl-signup-container--description--font-size: #{h.rem(16px)};
    }
  }

  body.newsletterEmbedPage & {
    --nl-signup-container--margin: 0;
  }
}
