@use 'assets/styles/utils/helper' as h;

:root {
  --blockquote--quote-text-margin: revert;
  --blockquote--quote--padding: 0 0 0 #{h.rem(20px)};
  --blockquote--border-color: var(--default-color);
  --blockquote--border-style: solid;
  --blockquote--border-left-width: 1px;
  --blockquote--color: var(--grey-60);
  --blockquote--font-family: inherit;
  --blockquote--font-size: #{h.rem(18px)};
  --blockquote--line-height: #{h.rem(27px)};
  --blockquote--margin: #{h.rem(48px)} 0 #{h.rem(48px)} #{h.rem(20px)};
  --blockquote--width: auto;
  --blockquote--quote--font-style: italic;
  --blockquote--quote--margin: 0;
  --blockquote--quote-before--background-color: var(--default-color);
  --blockquote--quote-before--mask-image: url("data:image/svg+xml;utf8,<svg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M7.77945 26.7174C11.4687 26.7174 14.5965 24.4694 14.5965 20.7763C14.5965 17.003 12.4311 14.9156 9.38346 14.9156C8.34085 14.9156 7.29825 15.317 6.81704 15.7184C6.65664 12.5071 9.06266 9.21543 14.5163 6.88719L13.8747 4.7998C4.81203 6.64634 0 11.4634 0 18.5284C0 24.068 3.84962 26.7174 7.77945 26.7174ZM25.1827 26.7174C28.872 26.7174 31.9998 24.4694 31.9998 20.7763C31.9998 17.003 29.8344 14.9156 26.7867 14.9156C25.7441 14.9156 24.7015 15.317 24.2203 15.7184C24.0599 12.5071 26.4659 9.21543 31.9196 6.88719L31.278 4.7998C22.2153 6.64634 17.4033 11.4634 17.4033 18.5284C17.4033 24.068 21.2529 26.7174 25.1827 26.7174Z' fill='white'/></svg>");
  --blockquote--quote-before--mask-repeat: no-repeat;
  --blockquote--quote-before--mask-size: contain;
  --blockquote--quote-before--content: '';
  --blockquote--quote-before--display: block;
  --blockquote--quote-before--height: #{h.rem(20px)};
  --blockquote--quote-before--left: #{h.rem(-10px)};
  --blockquote--quote-before--position: absolute;
  --blockquote--quote-before--top: #{h.rem(-27px)};
  --blockquote--quote-before--width: #{h.rem(20px)};
  --blockquote--source--color: var(--black);
  --blockquote--source--display: block;
  --blockquote--source--font-family: var(--founders-mono);
  --blockquote--source--font-size: #{h.rem(12px)};
  --blockquote--source--font-style: normal;
  --blockquote--source--letter-spacing: -0.04em;
  --blockquote--source--line-height: 1;
  --blockquote--source--margin: #{h.rem(20px)} 0 0 0;
  --blockquote--source--text-transform: uppercase;
  --blockquote--source--link--hover-color: var(--black);
  --blockquote--source--link--text-decoration: none;
  --blockquote--source--link--text-decoration-color: inherit;
  --blockquote--source--link--text-decoration-thickness: auto;
  --blockquote--source--link--text-underline-offset: auto;

  @include h.breakpoint-m {
    --blockquote--margin: #{h.rem(48px)} 0 #{h.rem(48px)} #{h.rem(30px)};
    --blockquote--quote--padding: 0 0 0 #{h.rem(30px)};
  }

  @include h.breakpoint-l {
    --blockquote--margin: #{h.rem(48px)} 0 #{h.rem(48px)} #{h.rem(40px)};
    --blockquote--quote--padding: 0 0 0 #{h.rem(40px)};
  }

  @include h.breakpoint-x {
    --blockquote--margin: #{h.rem(48px)} 0 #{h.rem(48px)} #{h.rem(9px)};
    --blockquote--quote--padding: 0 0 0 #{h.rem(30px)};
  }
}

body.msnbc {
  --blockquote--border-color: var(--blue-40);
  --blockquote--quote-before--background-color: var(--blue-40);
}

body.select {
  --blockquote--border-color: var(--blue-60);
  --blockquote--color: #555;
  --blockquote--font-size: #{h.rem(17px)};
  --blockquote--line-height: #{h.rem(29px)};
  --blockquote--quote-before--background-color: var(--blue-60);
  --blockquote--source--color: #555;
  --blockquote--source--display: block;
  --blockquote--source--font-family: var(--default-text-font-family);
  --blockquote--source--font-size: #{h.rem(12px)};
  --blockquote--source--letter-spacing: 0;
  --blockquote--source--line-height: #{h.rem(21px)};
  --blockquote--source--margin: #{h.rem(16px)} 0 0 0;
}

body.today {
  --blockquote--color: var(--grey-60);
  --blockquote--font-family: var(--tertiary-font);
  --blockquote--font-size: #{h.rem(20px)};
  --blockquote--line-height: #{h.rem(30px)};
  --blockquote--border-color: var(--accent-purple);
  --blockquote--border-left-width: #{h.rem(22px)};
  --blockquote--margin: #{h.rem(40px)} 0 #{h.rem(40px)} 0;
  --blockquote--quote-before--background-color: var(--accent-blue);
  --blockquote--quote-before--position: relative;
  --blockquote--quote-before--left: 0;
  --blockquote--quote-before--top: 0;
  --blockquote--quote-before--height: #{h.rem(26px)};
  --blockquote--quote-before--width: #{h.rem(26px)};
  --blockquote--quote--padding: 0 0 0 #{h.rem(23px)};
  --blockquote--quote-text-margin: #{h.rem(15px)} 0 #{h.rem(30px)} 0;
  --blockquote--source--color: var(--grey-60);
  --blockquote--source--font-family: var(--secondary-font);
  --blockquote--source--font-size: #{h.rem(12px)};
  --blockquote--source--font-style: normal;
  --blockquote--source--font-weight: bold;
  --blockquote--source--line-height: #{h.rem(21px)};
  --blockquote--source--letter-spacing: 0;
  --blockquote--source--text-transform: uppercase;
  --blockquote--source--margin: #{h.rem(16px)} 0 0 0;
  --blockquote--source--link--hover-color: var(--orange-30);
  --blockquote--source--link--text-decoration: underline;
  --blockquote--source--link--text-decoration-color: var(--accent-orange);
  --blockquote--source--link--text-decoration-thickness: #{h.rem(2px)};
  --blockquote--source--link--text-underline-offset: #{h.rem(3px)};

  @include h.breakpoint-m {
    --blockquote--margin: #{h.rem(48px)} 0 #{h.rem(48px)} 0;
  }

  @include h.breakpoint-l {
    --blockquote--margin: #{h.rem(60px)} 0 #{h.rem(60px)} 0;
  }
}
