@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.pullquote {
  border-image: var(--pullquote--border-image);
  position: relative; // for ::before quote mark
  margin: var(--pullquote--margin);
  padding: var(--pullquote--padding);

  @include h.responsive-variations(border-style, t.$pullquote--border-style);
  @include h.responsive-variations(border-width, t.$pullquote--border-width);

  body.today & {
    &::before {
      background: var(--grey-20);
      content: '';
      display: block;
      position: absolute;
      width: var(--pullquote--before--width);
      height: 100%;
      left: -20px;
      top: h.rem(18px);
      transform: rotate(2deg) skew(2deg);
    }
  }
}

.pullquote--small {
  @include h.responsive-variations(clear, t.$pullquote--small--clear);
  @include h.responsive-variations(float, t.$pullquote--small--float);
  @include h.responsive-variations(margin, t.$pullquote--small--margin);
  @include h.responsive-variations(width, t.$pullquote--small--width);
  padding: var(--pullquote--small--padding);

  body.today & {
    &::before {
      @include h.breakpoint-m {
        transform: rotate(2deg) skew(2deg);
        width: calc(100% + 60vw);
        left: -60vw;
        top: h.rem(8px);
      }

      @include h.breakpoint-x {
        top: h.rem(5px);
      }

      @include h.breakpoint-xx {
        top: -0.2vw;
      }
    }
  }
}

.pullquote--large {
  @include h.responsive-variations(width, t.$pullquote--large--width);
  margin: var(--pullquote--large--margin);
  padding: var(--pullquote--large--padding);

  body.today & {
    &::before {
      width: 100vw;

      @include h.breakpoint-m {
        height: 94%;
        top: h.rem(18px);
        left: 50%;
        transform: translate(-50%) rotate(2deg) skew(2deg);
      }

      @include h.breakpoint-l {
        top: h.rem(17px);
        transform: translate(-50%) rotate(1.2deg) skew(1.2deg);
      }

      @include h.breakpoint-x {
        top: 1.4vw;
        left: -75px;
        transform: rotate(1.2deg) skew(1.2deg);
      }
    }
  }
}

.pullquote__quote {
  color: var(--pullquote--quote--color);
  font-size: var(--pullquote--quote--font-size);
  font-style: var(--pullquote--quote--font-style);
  font-weight: var(--pullquote--quote--font-weight);
  line-height: var(--pullquote--quote--line-height);
  font-family: var(--pullquote--quote--font-family);
  padding: var(--pullquote--quote--padding);
  margin: 0;
  position: relative;
}

.pullquote--small .pullquote__quote {
  font-size: var(--pullquote--quote--small--font-size);
}

.pullquote--large .pullquote__quote {
  line-height: var(--pullquote--quote--large--line-height);
  font-size: var(--pullquote--quote--large--font-size);
}

.pullquote__quote::before {
  background-color: var(--pullquote--quote-before--background-color);
  height: var(--pullquote--quote-before--height);
  width: var(--pullquote--quote-before--width);

  @include h.responsive-variations(mask-image, t.$pullquote--quote-before--mask-image);
  @include h.responsive-variations(mask-repeat, t.$pullquote--quote-before--mask-repeat);
  @include h.responsive-variations(mask-size, t.$pullquote--quote-before--mask-size);
  @include h.responsive-variations(content, t.$pullquote--quote-before--content);
  @include h.responsive-variations(display, t.$pullquote--quote-before--display);
  @include h.responsive-variations(left, t.$pullquote--quote-before--left);
  @include h.responsive-variations(position, t.$pullquote--quote-before--position);
  @include h.responsive-variations(top, t.$pullquote--quote-before--top);

  .pullquote--small & {
    height: var(--pullquote--quote-before--small--height);
    width: var(--pullquote--quote-before--small--width);
  }
}

.pullquote__attribution {
  font-family: var(--pullquote--attribution--font-family);
  font-size: var(--pullquote--attribution--font-size);
  line-height: var(--pullquote--attribution--line-height);
  margin: var(--pullquote--attribution--margin);
  font-weight: var(--pullquote--attribution--font-weight);
  position: relative;

  @include h.responsive-variations(font-style, t.$pullquote--attribution--font-style);
  @include h.responsive-variations(text-transform, t.$pullquote--attribution--text-transform);

  .pullquote--large & {
    margin: var(--pullquote--attribution--margin--large);
  }

  @include h.breakpoint-l {
    .select .pullquote--large & {
      text-align: center;
    }
  }
}

.pullquote__cite {
  color: t.$pullquote--cite--color;
  font-style: t.$pullquote--cite--font-style;
}

.pullquote__quip {
  color: var(--pullquote--quip--color);
}
