@use 'assets/styles/utils/helper' as h;

$liftout--border-style: solid !default;
$liftout--border-width: 1px 0 0 0 !default;

$liftout--small--clear: (m: both) !default;
$liftout--small--float: (m: left) !default;
$liftout--small--width: (
  m: h.rem(325px),
  l: h.rem(360px),
) !default;

$liftout--large--width: (
  m: calc(100% + 120px),
  l: h.rem(920px),
  x: h.rem(960px),
) !default;

$liftout--text--margin: h.rem(24px) 0 0 !default;

:root {
  --liftout--border-color: var(--default-color);
  --liftout--border-image: none;
  --liftout--text--color: var(--grey-70);
  --liftout--text--font-family: var(--founders-cond);
  --liftout--text--font-size: #{h.rem(32px)};
  --liftout--small--text--font-size: var(--liftout--text--font-size);
  --liftout--text--padding: 0;
  --liftout--text--font-style: normal;
  --liftout--text--font-weight: normal;
  --liftout--text--line-height: 1;
  --liftout--large--text--font-size: var(--liftout--text--font-size);
  --liftout--large--text--padding: 0;
  --liftout--large--text--line-height: var(--liftout--text--line-height);
  --liftout--margin: #{h.rem(48px)} 0;
  --liftout--large--margin: var(--liftout--margin);
  --liftout--small--margin: var(--liftout--margin);
  --liftout--before--width: calc(40px + 100%);

  @include h.breakpoint-m {
    --liftout--large--margin: #{h.rem(48px 0 48px -60px)};
    --liftout--small--margin: #{h.rem(8px 30px 48px -59px)};
  }

  @include h.breakpoint-l {
    --liftout--large--margin: #{h.rem(48px 0 48px 0)};
    --liftout--small--margin: #{h.rem(8px 40px 48px 0)};
    --liftout--large--text--font-size: #{h.rem(48px)};
  }

  @include h.breakpoint-x {
    --liftout--large--margin: #{h.rem(48px 0 48px -100px)};
    --liftout--small--margin: #{h.rem(8px 40px 48px -100px)};
    --liftout--large--text--font-size: #{h.rem(60px)};
  }
}

body.select {
  --liftout--border-color: var(--blue-60);
  --liftout--text--color: #2a2a2a;
  --liftout--text--font-family: var(--default-text-font-family);
  --liftout--text--font-size: #{h.rem(30px)};
  --liftout--large--text--font-size: #{h.rem(30px)};
  --liftout--small--text--font-size: #{h.rem(30px)};
  --liftout--large--text--line-height: 1.2;
  --liftout--text--font-weight: 900;
  --liftout--text--line-height: 1.2;

  @include h.breakpoint-l {
    --liftout--large--text--font-size: #{h.rem(52px)};
    --liftout--large--text--line-height: #{h.rem(60px)};
  }
}

body.think {
  --liftout--text--font-family: var(--publico-hed);
  --liftout--text--font-style: italic;
  --liftout--text--line-height: 1.25;
  --liftout--large--text--line-height: 1.25;
}

body.today {
  --liftout--border-image: linear-gradient(to right, transparent, transparent) 1;
  --liftout--border-color: transparent;
  --liftout--text--font-family: var(--primary-font);
  --liftout--text--font-style: normal;
  --liftout--text--font-size: #{h.rem(28px)};
  --liftout--text--color: var(--secondary-color);
  --liftout--text--line-height: 1.5;
  --liftout--large--text--line-height: 1.5;
  --liftout--margin: #{h.rem(60px)} 0;
  --liftout--large--text--font-size: #{h.rem(28px)};
  --liftout--small--text--font-size: #{h.rem(28px)};
  --liftout--text--padding: #{h.rem(15px 0 35px)};
  --liftout--large--text--padding: #{h.rem(10px 0 35px)};

  @include h.breakpoint-m {
    --liftout--large--margin: #{h.rem(60px 0 60px -60px)};
    --liftout--small--margin: #{h.rem(18px 30px 38px -59px)};
    --liftout--text--font-size: #{h.rem(24px)};
    --liftout--large--text--font-size: #{h.rem(32px)};
    --liftout--small--text--font-size: #{h.rem(24px)};
    --liftout--text--padding: #{h.rem(0 10px 35px 0)};
    --liftout--large--text--padding: #{h.rem(25px 0 40px)};
  }

  @include h.breakpoint-l {
    --liftout--large--margin: #{h.rem(60px 0 60px 0)};
    --liftout--small--margin: #{h.rem(28px 40px 28px 0)};
    --liftout--text--font-size: #{h.rem(28px)};
    --liftout--large--text--font-size: #{h.rem(48px)};
    --liftout--small--text--font-size: #{h.rem(32px)};
    --liftout--large--text--padding: #{h.rem(40px 0)};
  }

  @include h.breakpoint-x {
    --liftout--large--margin: #{h.rem(60px 0 60px -100px)};
    --liftout--small--margin: #{h.rem(28px 40px 28px -100px)};
    --liftout--text--padding: #{h.rem(0 10px 40px 0)};
  }
}
