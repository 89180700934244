@use 'assets/styles/utils/helper' as h;
@use 'theme.tokens' as t;

.stepSubhed {
  font-family: var(--oswald-font-family);
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.citations {
  border-top: 1px dashed var(--citations-border-color);
  color: var(--citations-text-color);
  padding-top: 12px;
  font-size: 15px;
  line-height: 1.4;

  @include h.breakpoint-m {
    font-size: 16px;
    padding-top: 16px;
  }

  ol {
    margin-top: 0;
    padding-left: 20px;

    & > li {
      margin-bottom: 6px;
    }
  }

  a {
    color: var(--citations-link-color);

    &:hover {
      opacity: 0.7;
    }

    &:active {
      opacity: 0.5;
    }
  }
}
