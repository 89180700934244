@use 'assets/styles/utils/helper' as h;

.msnbcDailyRecircBody {
  // container rules
  font-family: var(--founders-cond);
  font-weight: normal;
  background-color: #f2f2f2;
  clear: both;
  display: none;
  line-height: 1;
  padding: h.rem(30px 20px);
  position: relative;

  @include h.breakpoint-m {
    margin-left: -1 * h.$gutter-tablet;
    padding: h.rem(30px 30px 40px);
    width: calc(100% + (#{h.$gutter-tablet} * 2));
  }

  @include h.breakpoint-l {
    margin-left: unset;
    padding: h.rem(30px 40px 40px);
    width: calc(100% + 20px);
  }

  @include h.breakpoint-x {
    margin-left: -1 * h.$gutter-desktop;
    padding: h.rem(30px 40px 40px);
    width: calc(100% + (#{h.$gutter-desktop} * 2));
  }

  .head {
    @include h.breakpoint-m {
      align-items: center;
      display: flex;
      flex-flow: row wrap;
    }

    &::after {
      background-image: linear-gradient(to right, #555 20%, transparent 0);
      background-size: 5px 1px;
      content: ' ';
      display: block;
      height: 1px;
      margin: h.rem(12px) 0 h.rem(20px) 0;
      width: 100%;
    }
  }

  &.hasItems {
    display: block;
  }

  &.hasColumnist .head {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
  }

  .headshot {
    border-radius: 50%;
    height: h.rem(50px);
    width: h.rem(50px);
    overflow: hidden;
    margin-right: h.rem(12px);
  }

  .title {
    font-size: h.rem(24px);
    line-height: 1;
    margin: 0 0 h.rem(8px) 0;

    @include h.breakpoint-m {
      font-size: h.rem(32px);
      margin: 0 h.rem(30px) 0 0;
    }
  }

  .description {
    font-family: var(--publico-txt);
    font-weight: normal;
    font-size: h.rem(14px);
    line-height: 1.5;
    margin: 0;
  }

  .body {
    display: block;
    margin-bottom: h.rem(-30px);

    @include h.breakpoint-m {
      display: flex;
      flex-flow: row nowrap;
      margin-bottom: h.rem(-40px);
    }
  }

  .item {
    display: flex;
    flex-flow: row nowrap;
    margin: 0 0 h.rem(20px) 0;

    @include h.breakpoint-m {
      flex-basis: 50%;

      &:first-child {
        margin-right: h.rem(30px);
      }
    }
  }

  .tease {
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 0;
    height: h.rem(80px);
    line-height: 0;
    margin-right: h.rem(12px);
    position: relative;
    width: h.rem(80px);

    picture {
      display: block;
    }

    img {
      width: auto;
    }
  }

  .info {
    position: relative;
  }

  .unibrow {
    font-family: var(--founders-mono);
    font-weight: normal;
    font-size: h.rem(12px);
    color: var(--blue-40);
    margin: 0 0 h.rem(8px) 0;
    text-transform: uppercase;
  }

  .headline {
    font-size: h.rem(18px);
    margin: h.rem(8px) 0 h.rem(10px) 0;
  }

  .externalLink {
    align-items: center;
    color: var(--blue-40);
    display: flex;
    font-size: h.rem(14px);
    margin-top: h.rem(10px);

    :global(.icon) {
      font-size: 11px;
      margin: 4px 0 0 4px;
    }
  }

  .author {
    font-family: var(--founders-mono);
    font-weight: normal;
    font-size: 12px;
    letter-spacing: -0.04em;
    margin: 10px 0 0;
    padding: 0;

    > * {
      &::before {
        content: ', ';
      }

      &:last-child::before {
        content: ' & ';
      }

      &:first-child::before {
        content: '';
      }
    }
  }
}
