@use 'assets/styles/utils/helper' as h;
@use 'theme.tokens' as t;

.ggWrapper {
  background-color: var(--white);
  margin: var(--gift-guide-wrapper-margin);
}

.ggInner {
  margin: 0 auto;
  padding: var(--gift-guide-inner-padding);
  width: auto;

  @include h.breakpoint-l {
    max-width: h.rem(1000px);
  }

  @include h.breakpoint-x {
    max-width: h.rem(1240px);
  }
}

.title {
  color: var(--gift-guide-title-color);
  font-family: var(--gift-guide-title-font-family);
  font-size: var(--text-32);
  font-style: var(--gift-guide-title-font-style);
  font-weight: var(--normal);
  line-height: var(--default-headline-leading);
  margin: 0 0 var(--spacing-7) 0;
  padding: var(--gift-guide-title-padding);
  text-align: center;
  background: var(--gift-guide-title-background);

  @include h.breakpoint-m {
    font-size: var(--text-48);
    margin: 0 0 var(--spacing-8) 0;
  }
}

.items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--gift-guide-items-gap);
  margin: 0 var(--space-24);

  @include h.breakpoint-m {
    grid-template-columns: repeat(4, 1fr);
  }
}

.item {
  text-align: center;
  margin: 0;
  padding: 0;
  position: relative;

  &:not(:last-of-type)::after {
    @include h.breakpoint-m {
      content: '';
      width: 1px;
      height: 100%;
      right: var(--gift-guide-divider-right);
      top: 0;
      background-image: var(--gift-guide-item-divider);
      background-size: 1px 4px;
      background-repeat: repeat-y;
      position: absolute;
    }
  }
}

.itemThumbnail {
  display: block;
  line-height: 0;

  img {
    border-radius: var(--gift-guide-img-border-radius);
    display: block;
    height: auto;
    margin: 0 auto;
    width: 100px;

    @include h.breakpoint-l {
      width: 134px;
    }
  }

  &:hover {
    img {
      transform: var(--gift-guide-thumbnail-hover-transform);
    }
  }
}

.itemUnibrow {
  color: var(--default-color);
  font-family: var(--founders-mono);
  font-size: var(--text-12);
  font-weight: normal;
  letter-spacing: var(--tracking-tight);
  line-height: 1;
  margin: 10px 0;
  text-transform: uppercase;
}

.imageContainer {
  position: relative;
  margin: 0 auto var(--gift-guide-thumbnail-margin);
  width: 100px;

  @include h.breakpoint-l {
    width: 134px;
  }
}

.itemTitle {
  color: var(--gift-guide-item-title-color);
  font-family: var(--gift-guide-item-title-font-family);
  font-size: var(--text-16);
  font-weight: var(--normal);
  line-height: var(--default-headline-leading);
  margin: var(--gift-guide-item-title-margin);

  &:hover {
    a {
      border-bottom: var(--gift-guide-item-title-hover-border);
    }
  }
}

.item:has(.itemThumbnail:hover, .itemTitle:hover) {
  .itemThumbnail img {
    transform: var(--gift-guide-thumbnail-hover-transform);
  }

  .itemTitle a {
    border-bottom: var(--gift-guide-item-title-hover-border);
  }
}
