@use 'assets/styles/utils/helper' as h;
@use 'theme.tokens' as t;

.statement {
  background-color: var(--background-color);
  padding: var(--spacing);
  margin: auto auto var(--spacing);
  box-shadow: 0 4px 4px 0 #0000000f;
}

.statementHeading {
  font-family: var(--oswald-font-family);
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.statementText {
  margin-bottom: var(--text-margin);
  font-family: var(--public-hed);
  line-height: 1.25;
  font-size: 18px;

  @include h.breakpoint-m {
    font-size: 20px;
  }
}

.statementFooter {
  font-family: var(--oswald-font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 1.25;

  @include h.breakpoint-m {
    font-size: 16px;
  }
}
