@use 'assets/styles/utils/helper' as h;

.statement {
  --background-color: #f2f2f2;
  --spacing: 16px;
  --text-margin: 6px;

  @include h.breakpoint-m {
    --spacing: 20px;
    --text-margin: 8px;
  }
}
