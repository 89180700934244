@use 'assets/styles/utils/helper' as h;

:root {
  --related-item-background-image: linear-gradient(to right, var(--grey-60) 10%, var(--neutral-10) 20%);
  --related-item-eyebrow-color: var(--default-color);
  --related-item-eyebrow-hover-color: var(--related-item-eyebrow-color);
  --related-item-eyebrow-font-family: var(--founders-mono);
  --related-item-eyebrow-font-weight: normal;
  --related-item-eyebrow-letter-spacing: normal;
  --related-item-eyebrow-wrapper-padding: #{h.rem(0 0 6px)};
  --related-item-single-label-bg-color: var(--white);
  --related-item-title-font: var(--founders-cond);
  --related-item-title-font-size: #{h.rem(18px)};
  --related-item-title-font-weight: normal;
  --related-item-title-font-line-height: #{h.rem(20px)};
  --related-item-title-color: inherit;
  --related-item-border-top: 1px solid var(--grey-40);
  --related-item-border-bottom: none;
  --related-item-link-line-height: #{h.rem(18px)};
  --related-item-title-single-font-size: #{h.rem(18px)};
  --related-item-title-single-font-line-height: #{h.rem(18px)};
  --related-item-eyebrow-line-height: #{h.rem(12px)};
  --related-item-thumbnail-wrapper-margin-right: #{h.rem(8px)};

  @include h.breakpoint-m {
    --related-item-title-font-size: #{h.rem(16px)};
    --related-item-title-single-font-size: #{h.rem(20px)};
    --related-item-title-single-font-line-height: #{h.rem(20px)};
  }
}

body.today {
  --related-item-single-label-bg-color: var(--neutral-10);
  --related-item-title-font-size: #{h.rem(16px)};
  --related-item-title-single-font-line-height: #{h.rem(18px)};
  --related-item-background-image: linear-gradient(to right, var(--grey-30), var(--grey-30));
  --related-item-title-font: var(--secondary-font);
  --related-item-title-color: var(--grey-60);
  --related-item-thumbnail-wrapper-margin-right: #{h.rem(20px)};
  --related-item-thumbnail-image-border-radius: 0;
  --related-item-eyebrow-font-family: var(--secondary-font);
  --related-item-eyebrow-font-weight: 700;
  --related-item-eyebrow-color: var(--grey-70);
  --related-item-eyebrow-hover-color: var(--secondary-color);
  --related-item-link-line-height: 1.5;
  --related-item-title-single-font-size: #{h.rem(16px)};

  @include h.breakpoint-m {
    --related-item-title-single-font-size: #{h.rem(20px)};
    --related-item-title-single-font-line-height: #{h.rem(22.5px)};
  }
}

body.globalcitizen {
  --related-item-eyebrow-color: var(--red-40);
}

body.think {
  --related-item-title-font: var(--publico-hed);
  --related-item-title-font-size: #{h.rem(16px)};
  --related-item-title-single-font-size: #{h.rem(18px)};
  --related-item-title-single-font-line-height: #{h.rem(18px)};

  @include h.breakpoint-m {
    --related-item-title-single-font-size: #{h.rem(18px)};
    --related-item-title-single-font-line-height: #{h.rem(22.5px)};
  }
}

body.select {
  --related-item-title-font: var(--lato);
  --related-item-title-font-size: #{h.rem(14px)};
  --related-item-title-single-font-size: #{h.rem(14px)};
  --related-item-title-single-font-line-height: #{h.rem(16.8px)};
  --related-item-title-font-weight: 700;
  --related-item-title-color: #2a2a2a;
  --related-item-eyebrow-color: var(--blue-60);
  --related-item-eyebrow-font-family: var(--lato);
  --related-item-eyebrow-letter-spacing: 0;
  --related-item-eyebrow-wrapper-padding: #{h.rem(0 0 8px)};
  --related-item-border-top: none;
  --related-item-border-bottom: 1px solid #ccc;
  --related-item-link-line-height: 120%;
}
