@use 'assets/styles/utils/helper' as h;

:root {
  --listicle-product-headline-font: var(--default-headline-font-family);
  --listicle-product-headline-color: var(--default-color);
  --listicle-product-headline-letter-spacing: 0;
  --listicle-product-headline-line-height: 1;
  --listicle-product-headline-weight: normal;
  --listicle-product-headline-hover-opacity: var(--default-hover-opacity);
  --listicle-product-headline-bottom-spacing: #{h.rem(20px)};
  --listicle-product-cost-border-radius: 0;
  --listicle-product-cost-font-family: inherit;
  --listicle-product-cost-font-weight: normal;
  --listicle-product-cost-line-height: 1;
  --listicle-product-cost-letter-spacing: normal;
  --listicle-product-vendor-border-radius: 0;
  --listicle-product-vendor-font-family: inherit;
  --listicle-product-vendor-font-size: inherit;
  --listicle-product-vendor-line-height: 1;
  --listicle-product-vendor-letter-spacing: normal;
  --listicle-product-seller-name-font: var(--founders-mono);
  --listicle-product-border-width: 1px;
  --listicle-product-card-info-border-width: 1px;
  --listicle-product-card-info-margin-left: 40px;
  --listicle-product-card-info-margin-right: 40px;
  --listicle-product-card-container-border-after-content: '';
  --listicle-product-border-after-content: none;
  --listicle-product-border-after-display: none;
  --listicle-product-border-after-height: none;
  --listicle-product-border-after-background-image: none;
  --listicle-product-border-after-background-repeat: inherit;
  --listicle-product-border-after-background-size: inherit;
  --listicle-product-description-color: var(--grey-60);
  --listicle-product-description-margin-bottom: #{h.rem(24px)};
  --listicle-product-description-color--large: var(--grey-70);
  --listicle-product-border-color: var(--default-color);
  --listicle-product-mobile-gutter: 20px;
  --listicle-product-image-text-gutter: 30px;
  --listicle-product-image-text-gutter-small: 30px;
  --listicle-product-mobile-spacer: 20px;
  --listicle-product-button-margin-bottom: #{h.rem(10px)};
  --listicle-product-grid-row-max-width: calc(300px + (var(--listicle-product-mobile-spacer) * 2));
  --neg-listicle-product-mobile-spacer: calc(var(--listicle-product-mobile-spacer) * -1);
  --listicle-product-tablet-spacer: calc(var(--listicle-product-mobile-spacer) * 2);
  --neg-listicle-product-tablet-spacer: calc(var(--listicle-product-tablet-spacer) * -1);
  --listicle-product-vendor-justify-content: center;

  @include h.breakpoint-m {
    --listicle-product-card-info-border-width: 0;
  }
}

body.today {
  --listicle-product-headline-font: var(--secondary-font);
  --listicle-product-headline-line-height: 1.5;
  --listicle-product-headline-color: var(--grey-70);
  --listicle-product-headline-letter-spacing: 0;
  --listicle-product-headline-weight: 700;
  --listicle-product-headline-bottom-spacing: #{h.rem(8px)};
  --listicle-product-description-margin-bottom: #{h.rem(20px)};
  --listicle-product-description-color--large: var(--grey-60);
  --listicle-product-image-text-gutter: 40px;
  --listicle-product-image-text-gutter-small: 20px;
  --listicle-product-border-width: 0;
  --listicle-product-card-container-border-after-content: none;
  --listicle-product-card-info-margin-left: 0;
  --listicle-product-card-info-margin-right: 0;
  --listicle-product-cost-font-family: var(--secondary-font);
  --listicle-product-grid-row-max-width: none;
  --listicle-product-mobile-spacer: 0;
  --listicle-product-vendor-font-size: 16px;
  --listicle-product-vendor-line-height: 1.5;
  --listicle-product-button-margin-bottom: #{h.rem(8px)};
  --listicle-product-headline-hover-opacity: 1;
  --listicle-product-tablet-spacer: 0;
  --listicle-product-pros-cons-font-family: var(--secondary-font);
  --listicle-product-pros-cons-font-size: 18px;
  --listicle-product-pros-cons-font-line-height: 150%;
  --listicle-product-pros-cons-font-family-item: var(--tertiary-font);
  --listicle-product-pros-cons-background: #fff2eb;
  --listicle-product-pros-cons-link-color: #2a2a2a;
  --listicle-product-pros-cons-link-border-bottom: 2px solid #ffb186;

  @include h.breakpoint-m {
    --listicle-product-grid-row-max-width: 250px;
    --listicle-product-card-info-margin-left: 40px;
    --listicle-product-card-info-margin-right: 40px;
  }
}

body.globalcitizen {
  --listicle-product-border-color: var(--red-40);
  --listicle-product-headline-color: var(--grey-50);
}

body.entretenimiento {
  --listicle-product-headline-letter-spacing: 0.4px;
  --listicle-product-headline-weight: 600;
  --listicle-product-headline-bottom-spacing: #{h.rem(12px)};
  --listicle-product-cost-font-family: var(--founders-mono);
}

body.shows {
  --listicle-product-headline-letter-spacing: 0.4px;
  --listicle-product-headline-weight: 600;
  --listicle-product-headline-bottom-spacing: #{h.rem(12px)};
}

body.telemundo {
  --listicle-product-headline-letter-spacing: 0.4px;
  --listicle-product-headline-weight: 600;
  --listicle-product-headline-bottom-spacing: #{h.rem(12px)};
  --listicle-product-cost-font-family: var(--founders-mono);
}

body.select {
  --listicle-product-headline-font: var(--lato);
  --listicle-product-headline-color: var(--blue-60);
  --listicle-product-headline-letter-spacing: normal;
  --listicle-product-headline-line-height: 120%;
  --listicle-product-headline-weight: 900;
  --listicle-product-headline-bottom-spacing: #{h.rem(16px)};
  --listicle-product-description-color: #555;
  --listicle-product-description-color--large: #555;
  --listicle-product-vendor-border-radius: 0 100px 100px 0;
  --listicle-product-vendor-font-family: var(--lato);
  --listicle-product-vendor-font-size: #{h.rem(17px)};
  --listicle-product-vendor-letter-spacing: -0.5%;
  --listicle-product-seller-name-font: var(--lato);
  --listicle-product-border-width: 0;
  --listicle-product-card-info-border-width: 0;
  --listicle-product-border-after-content: ' ';
  --listicle-product-border-after-display: flex;
  --listicle-product-border-after-height: #{h.rem(18px)};
  --listicle-product-border-after-background-image: var(--default-background-image);
  --listicle-product-border-after-background-repeat: repeat-x;
  --listicle-product-border-after-background-size: 18px;
  --listicle-large-product-after-left: 20px;
  --listicle-product-cost-border-radius: 100px 0 0 100px;
  --listicle-product-cost-font-family: var(--lato);
  --listicle-product-cost-letter-spacing: -0.5%;
  --listicle-product-cost-font-weight: 700;
  --listicle-product-cost-line-height: 100%;
  --listicle-product-vendor-justify-content: flex-start;
  --listicle-product-pros-cons-font-family: Lato;
  --listicle-product-pros-cons-font-size: 15px;
  --listicle-product-pros-cons-font-line-height: 24px;
  --listicle-product-pros-cons-font-family-item: Lato;
  --listicle-product-pros-cons-background: #d7edff;
  --listicle-product-pros-cons-link-color: #0477c9;
  --listicle-product-pros-cons-link-border-bottom: none;
  --listicle-product-card-info-margin-left: 0;
  --listicle-product-card-info-margin-right: 0;
  --listicle-product-mobile-gutter: 0;

  @include h.breakpoint-m {
    --listicle-large-product-after-left: 0;
  }

  @include h.breakpoint-l {
    --listicle-large-product-after-left: 95px;
  }

  @include h.breakpoint-x {
    --listicle-large-product-after-left: 0;
  }
}
