@use 'assets/styles/utils/helper' as h;

$telemundo: #eb1010 !default;
$select: #0477c9 !default;

:root {
  --article-summarybox-padding: 0 8px 8px 0;
  --article-summarybox-margin-top: 16px;
  --article-summarybox-margin-bottom: 48px;
  --article-summarybox-items-underline-width: 1px;
  --article-summarybox-items-underline-color: var(--default-color);
  --article-summarybox-items-underline-padding: 2px;
  --article-summarybox-vertical-color: var(--default-color);
  --article-summarybox-border-color: var(--default-color);
  --article-summarybox-border-size: 2px;
  --article-summarybox-border: var(--default-color) 2px solid;
  --article-summarybox-content-padding: 28px 20px 24px;
  --article-summarybox-border-stripe-color: var(--default-color);
  --article-summarybox-border-shadow-color: #dbebff;
  --article-summarybox-items-font-color: inherit;
  --article-summarybox-breaking-red: #{h.$breaking-red};
  --article-summarybox-headline-font-family: var(--founders-cond);
  --article-summarybox-headline-line-height: #{h.rem(20px)};
  --article-summarybox-headline-font-color: var(--grey-70);
  --article-summarybox-headline-font-weight: 400;
  --article-summarybox-headline-font-size: #{h.rem(20px)};
  --article-summarybox-items-link-color: var(--default-color);
  --article-summarybox-items-link-hover-color: var(--default-color);
  --article-summarybox-items-hover-opacity: var(--default-hover-opacity);
  --article-summarybox-items-active-opacity: var(--default-active-opacity);
  --article-summarybox-items-link-underline-color: var(--default-color);
  --article-summarybox-items-margin: 0;
  --article-summarybox-items-margin-bottom: #{ h.rem(12px)};
  --article-summarybox-items-margin-left: 18px;
  --article-summarybox-items-padding-left: 0;
  --article-summarybox-items-font-size: inherit;

  @include h.breakpoint-m {
    --article-summarybox-content-padding: 40px;
    --article-summarybox-headline-font-size: #{h.rem(28px)};
    --article-summarybox-headline-line-height: #{h.rem(28px)};
    --article-summarybox-items-margin-bottom: #{h.rem(24px)};
  }
}

body.telemundo,
body.entretenimiento,
body.shows,
body.deportes,
body.noticias {
  --article-summarybox-vertical-color: var(--blue-40);
  --article-summarybox-border-color: var(--blue-40);
  --article-summarybox-border-stripe-color: #{$telemundo};
  --article-summarybox-border-shadow-color: var(--blue-20);
  --article-summarybox-border: var(--blue-40) 2px solid;
}

body.deportes,
body.noticias {
  --article-summarybox-breaking-red: #{$telemundo};
}

body.today {
  --article-summarybox-vertical-color: var(--orange-30);
  --article-summarybox-border: none;
  --article-summarybox-headline-font-color: var(--grey-70);
  --article-summarybox-items-active-opacity: var(--default-hover-opacity);
  --article-summarybox-padding: 0;
  --article-summarybox-margin-top: 48px;
  --article-summarybox-margin-bottom: 60px;
  --article-summarybox-border-shadow-color: transparent;
  --article-summarybox-content-padding: 0;
  --article-summarybox-border-color: transparent;
  --article-summarybox-border-size: 0;
  --article-summarybox-headline-font-family: var(--primary-font);
  --article-summarybox-headline-font-size: #{h.rem(24px)};
  --article-summarybox-headline-line-height: 1.5;
  --article-summarybox-items-font-color: var(--grey-70);
  --article-summarybox-items-link-color: var(--grey-70);
  --article-summarybox-items-hover-opacity: 1;
  --article-summarybox-items-underline-width: 2px;
  --article-summarybox-items-underline-color: var(--accent-orange);
  --article-summarybox-items-underline-padding: 0;
  --article-summarybox-items-margin: #{h.rem(12px)};
  --article-summarybox-items-margin-bottom: #{h.rem(24px)};
  --article-summarybox-items-font-size: #{h.rem(16px)};
  --article-summarybox-items-margin-left: 0;
  --article-summarybox-items-padding-left: #{h.rem(34px)};

  @include h.breakpoint-m {
    --article-summarybox-headline-font-size: #{h.rem(28px)};
    --article-summarybox-items-font-size: #{h.rem(18px)};
    --article-summarybox-items-margin: #{h.rem(16px)};
  }
}

body.select {
  --article-summarybox-items-link-color: #{$select};
  --article-summarybox-vertical-color: #{$select};
  --article-summarybox-border-color: #{$select};
  --article-summarybox-border-stripe-color: #74c5ff;
  --article-summarybox-border: #{$select} 2px solid;
  --article-summarybox-items-link-hover-color: var(--blue-60);
  --article-summarybox-headline-font-size: #{h.rem(20px)};
  --article-summarybox-headline-font-family: var(--lato);
  --article-summarybox-headline-font-weight: 900;

  @include h.breakpoint-m {
    --article-summarybox-headline-font-size: #{h.rem(24px)};
  }
}
