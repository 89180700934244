@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.nl-signup-input {
  padding: var(--nl-signup-input--padding);
}

.nl-signup-input__input-wrapper {
  position: relative;

  &:focus {
    outline: none;
  }
}

.nl-signup-input__form {
  display: flex;
  flex: 1;

  &--rail {
    padding: 0;
    height: 60px;
  }

  body.today &::before {
    content: ' ';
    position: absolute;
    height: 35px;
    background: var(--black);
    transform: rotate(1.5deg);
    width: 78px;
    top: 3px;
    left: -4px;

    @include h.breakpoint-m {
      width: 124px;
      left: -7px;
      top: 3px;
    }
  }

  .nl-signup-input__button {
    background-color: var(--nl-signup-input--button--background-color);
    border-color: var(--nl-signup-input--button--border-color);
    border-radius: var(--nl-signup-input--button--border-radius);
    border-style: var(--nl-signup-input--button--border-style);
    border-width: var(--nl-signup-input--button--border-width);
    color: var(--nl-signup-input--button--color);
    font-family: var(--nl-signup-input--button--font-family);
    font-size: var(--nl-signup-input--button--font-size);
    font-weight: var(--nl-signup-input--button--font-weight);
    line-height: var(--nl-signup-input--button--line-height);
    margin: var(--nl-signup-input--button--margin);
    max-width: var(--nl-signup-input--button--max-width);
    min-width: var(--nl-signup-input--button--min-width);
    padding: var(--nl-signup-input--button--padding);
    text-transform: var(--nl-signup-input--button--text-transform);
    width: var(--nl-signup-input--button--width);

    &.nl-signup-input__button--grayscale {
      border-color: var(--grey-70);
      color: var(--grey-70);
      background-color: var(--white);
    }

    &:hover {
      opacity: var(--nl-signup-input--button--hover--opacity);
      color: var(--button-text-hover-color);
      border-color: var(--button-border-hover-color);
    }

    &:active {
      opacity: var(--nl-signup-input--button--active--opacity);
    }

    &--rail {
      background-color: var(--nl-signup-input--button--color);
      color: var(--white);
      width: 85px;

      @include h.breakpoint-m-only {
        width: 157px;
      }

      &:hover {
        color: var(--nl-signup-input--button--color);
      }
    }
  }
}

.nl-signup-input__input {
  background-color: var(--nl-signup-input--input--background-color);
  border-top: var(--nl-signup-input--input--border-top);
  border-right: var(--nl-signup-input--input--border-right);
  border-left: var(--nl-signup-input--input--border-left);
  border-bottom:
    var(--nl-signup-input--input--border-bottom-width) var(--nl-signup-input--input--border-style)
    var(--nl-signup-input--input--border-color);
  color: var(--nl-signup-input--input--color);
  flex: 1 1 auto;
  font-family: var(--nl-signup-input--input--font-family);
  font-size: var(--nl-signup-input--input--font-size);
  line-height: var(--nl-signup-input--input--line-height);
  margin: var(--nl-signup-input--input--margin);
  outline: var(--nl-signup-input--input--outline);
  padding: var(--nl-signup-input--input--padding);
  height: var(--nl-signup-input--input--height);
  position: relative;

  &.nl-signup-input__input--grayscale {
    border-bottom: 1px solid var(--grey-50);
    font-family: var(--founders-mono);
    font-size: var(--text-14);
    padding-left: 20px;

    &::placeholder {
      opacity: 1;
      color: rgb(85, 85, 85);
    }
  }

  &--rail {
    border-bottom: solid 1px var(--signup-input-design-color);

    &::placeholder {
      font-size: 20px;
      font-style: italic;
      padding-left: 25px;
    }
  }

  &::placeholder {
    color: var(--nl-signup-input--input-placeholder--color);
  }

  &:focus {
    border-color: var(--nl-signup-input--input-focus--border-color);
  }

  &--invalid {
    background-color: var(--nl-signup-input--input-invalid--background-color);
    border-color: var(--nl-signup-input--input-invalid--border-color);

    &:focus {
      background-color: var(--nl-signup-input--input-invalid--background-color);
      border-color: var(--nl-signup-input--input-invalid--border-color);
    }
  }
}

.nl-signup-input__recaptcha-links {
  margin: var(--nl-signup-input--recaptcha-links--margin);

  @include h.breakpoint-m {
    display: inline;
  }

  &--rail {
    color: var(--nl-signup-input--input-focus--border-color);

    @include h.breakpoint-m-only {
      margin-left: 20px;
    }
  }
}

.nl-signup-input__recaptcha-link {
  color: var(--nl-signup-input--recaptcha-link--color);
  margin: var(--nl-signup-input--recaptcha-link--margin);
  border-bottom: var(--nl-signup-input--recaptcha-link--border-bottom);

  &:hover {
    opacity: var(--nl-signup-input--recaptcha-link--hover-opacity);
    color: var(--nl-signup-input--recaptcha-link--hover-color);
  }

  &:active {
    opacity: var(--nl-signup-input--recaptcha-link--active-opacity);
  }

  &--rail {
    line-height: 20px;
    color: var(--nl-signup-input--input-focus--border-color);
  }
}

.nl-signup-input__footer {
  font-family: var(--nl-signup-input--footer--font-family);
  font-size: var(--nl-signup-input--footer--font-size);
  font-style: var(--nl-signup-input--footer--font-style);
  font-weight: var(--nl-signup-input--footer--font-weight);
  line-height: var(--nl-signup-input--footer--line-height);
  letter-spacing: var(--nl-signup-input--footer--letter-spacing);
  margin: var(--nl-signup-input--footer--margin);
  text-transform: var(--nl-signup-input--footer--text-transform);
  color: var(--nl-signup-input--footer--color);

  &--grayscale {
    margin-top: 20px;
  }

  &--rail {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    color: var(--grey-60);

    @include h.breakpoint-m-only {
      display: block;
    }

    @include h.breakpoint-l {
      margin-top: 12px;
    }
  }

  .nl-signup-input__submitted-message {
    animation: slide-up 0.3s ease-in;
    border-color: var(--nl-signup-input--submitted-message--border-color);
    border-style: var(--nl-signup-input--submitted-message--border-style);
    border-width: var(--nl-signup-input--submitted-message--border-width);
    color: var(--nl-signup-input--submitted-message--color);
    flex: 1;
    justify-content: left;
    opacity: 1;
    padding: var(--nl-signup-input--submitted-message--padding);

    &--rail {
      color: var(--signup-input-design-color);
    }

    p {
      margin: 0;
      padding: 0;
    }
  }

  @keyframes slide-up {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
