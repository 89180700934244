@use './theme.tokens.scss';
@use 'assets/styles/utils/helper' as h;

.nl-signup-inline__container {
  margin: var(--nl-signup-container--margin);
  border-color: var(--nl-signup-container--border-color);
  border-style: var(--nl-signup-container--border-style);
  border-width: var(--nl-signup-container--border-width);
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.nl-signup-inline__header ~ .nl-signup-inline__container {
  margin-top: 0;

  &--with-picture {
    border-top: none;
    padding: 22px 20px;
    background-color: var(--nl-signup-container--with-picture--background-color, var(--white));

    @include h.breakpoint-s-only {
      padding: 22px 0 22px 3px;
      margin-left: -1.25rem;
      margin-right: -1.25rem;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      width: unset;
    }

    &::before {
      content: unset;
    }
  }
}

.nl-signup-inline__header {
  margin: 3rem 0 h.rem(5px) 0;
  font-size: var(--text-18);
  font-style: italic;
  color: var(--nl-signup-header--color);
}

.newsletterEmbedPage .nl-signup-inline__container {
  padding-top: 0;
}

.nl-signup-inline__container::before {
  background-color: var(--nl-signup-container--before--background-color);
  content: var(--nl-signup-container--before--content);
  height: var(--nl-signup-container--before--height);
  left: var(--nl-signup-container--before--left);
  position: var(--nl-signup-container--before--position);
  top: var(--nl-signup-container--before--top);
  width: var(--nl-signup-container--before--width);
}

.nl-signup-inline__metadata {
  display: flex;
  margin-bottom: 10px;
}

.nl-signup-inline__picture-container {
  display: flex;
  flex: 1;
  min-width: 115px;
  max-width: 115px;
  min-height: 100px;
  max-height: 115px;
  object-fit: cover;
  margin-right: 18px;
  position: relative;
}

.nl-signup-inline__details-container {
  flex-direction: column;
  display: flex;
  flex: 1;
  align-self: center;
}

.nl-signup-inline__details {
  flex: 0;
  min-width: 100%;
  flex-direction: column;
}

.nl-signup-inline__picture {
  min-width: 115px;
  max-width: 115px;
  min-height: 100px;
  max-height: 115px;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @include h.breakpoint-s-only {
    margin-right: 12px;
  }
}

.nl-signup-inline__title {
  color: var(--nl-signup-container--title--color);
  display: var(--nl-signup-container--title--display);
  font-family: var(--nl-signup-container--title--font-family);
  font-size: var(--nl-signup-container--title--font-size);
  font-style: var(--nl-signup-container--title--font-style);
  font-weight: var(--nl-signup-container--title--font-weight);
  line-height: var(--nl-signup-container--title--line-height);
  margin: var(--nl-signup-container--title--margin);

  &--with-picture {
    margin-top: 0;
    font-family: var(--founders-cond);
    color: var(--grey-70);
    font-style: normal;
    font-weight: var(--semibold);
    font-size: var(--text-20);

    @include h.breakpoint-m {
      font-size: var(--text-24);
      line-height: var(--text-24);
    }
  }
}

.nl-signup-inline__title::after {
  background-image: var(--nl-signup-container--title--after--background-image);
  content: var(--nl-signup-container--title--after--content);
  flex: var(--nl-signup-container--title--after--flex);
  height: var(--nl-signup-container--title--after--height);
  margin: var(--nl-signup-container--title--after--margin);
}

.nl-signup-inline__description {
  color: var(--nl-signup-container--description--color);
  font-family: var(--nl-signup-container--description--font-family);
  font-size: var(--nl-signup-container--description--font-size);
  line-height: var(--nl-signup-container--description--line-height);
  margin: var(--nl-signup-container--description--margin);

  &--with-picture {
    color: var(--grey-70);
    margin: 5px 0 0;
    line-height: var(--text-24);
  }
}

.grecaptcha-badge {
  opacity: 0;
}
