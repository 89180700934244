@use 'assets/styles/utils/helper' as h;

$pullquote--border-style: solid !default;
$pullquote--border-width: 1px 0 0 0 !default;

$pullquote--small--clear: (m: both) !default;
$pullquote--small--float: (m: left) !default;
$pullquote--small--margin: (
  m: h.rem(8px 30px 48px -59px),
  l: h.rem(8px 40px 48px 0),
  x: h.rem(8px 40px 48px -100px),
) !default;
$pullquote--small--width: (
  m: h.rem(325px),
  l: h.rem(360px),
) !default;

$pullquote--large--width: (
  m: calc(100% + 120px),
  l: h.rem(920px),
  x: h.rem(960px),
) !default;

$pullquote--quote-before--mask-image: url("data:image/svg+xml;utf8,<svg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M7.77945 26.7174C11.4687 26.7174 14.5965 24.4694 14.5965 20.7763C14.5965 17.003 12.4311 14.9156 9.38346 14.9156C8.34085 14.9156 7.29825 15.317 6.81704 15.7184C6.65664 12.5071 9.06266 9.21543 14.5163 6.88719L13.8747 4.7998C4.81203 6.64634 0 11.4634 0 18.5284C0 24.068 3.84962 26.7174 7.77945 26.7174ZM25.1827 26.7174C28.872 26.7174 31.9998 24.4694 31.9998 20.7763C31.9998 17.003 29.8344 14.9156 26.7867 14.9156C25.7441 14.9156 24.7015 15.317 24.2203 15.7184C24.0599 12.5071 26.4659 9.21543 31.9196 6.88719L31.278 4.7998C22.2153 6.64634 17.4033 11.4634 17.4033 18.5284C17.4033 24.068 21.2529 26.7174 25.1827 26.7174Z' fill='white'/></svg>") !default;
$pullquote--quote-before--mask-repeat: no-repeat !default;
$pullquote--quote-before--mask-size: contain !default;
$pullquote--quote-before--content: '' !default;
$pullquote--quote-before--display: block !default;
$pullquote--quote-before--left: 0 !default;
$pullquote--quote-before--position: absolute !default;
$pullquote--quote-before--top: -21px !default;

$pullquote--attribution--font-style: normal !default;
$pullquote--attribution--text-transform: uppercase !default;

$pullquote--cite--color: var(--black) !default;
$pullquote--cite--font-style: normal !default;

:root {
  --pullquote--margin: #{h.rem(48px)} 0;
  --pullquote--large--margin: var(--pullquote--margin);
  --pullquote--large--padding: 0;
  --pullquote--small--padding: 0;
  --pullquote--padding: 0;
  --pullquote--border-image: linear-gradient(to right, transparent, transparent 35px, var(--default-color) 35px) 1;
  --pullquote--quote--font-family: var(--default-headline-font-family);
  --pullquote--quote--color: var(--grey-70);
  --pullquote--quote--font-size: 2rem;
  --pullquote--quote--small--font-size: var(--pullquote--quote--font-size);
  --pullquote--quote--font-style: normal;
  --pullquote--quote--font-weight: normal;
  --pullquote--quote--line-height: 1;
  --pullquote--quote--padding: #{h.rem(24px)} 0 0;
  --pullquote--quote--large--line-height: 1;
  --pullquote--quote--large--font-size: var(--pullquote--quote--font-size);
  --pullquote--large--quote--line-height: var(--pullquote--quote--line-height);
  --pullquote--quote-before--background-color: var(--default-color);
  --pullquote--quote-before--height: 25px;
  --pullquote--quote-before--width: 25px;
  --pullquote--quote-before--small--height: var(--pullquote--quote-before--height);
  --pullquote--quote-before--small--width: var(--pullquote--quote-before--width);
  --pullquote--attribution--font-family: var(--founders-mono);
  --pullquote--attribution--font-size: var(--text-14);
  --pullquote--attribution--line-height: 1.25;
  --pullquote--attribution--margin: #{h.rem(24px)} 0 0;
  --pullquote--attribution--margin--large: var(--pullquote--attribution--margin);
  --pullquote--attribution--font-weight: normal;
  --pullquote--quip--color: var(--grey-70);
  --pullquote--before--width: 100vw;

  @include h.breakpoint-m {
    --pullquote--large--margin: #{h.rem(48px 0 48px -60px)};
    --pullquote--before--width: calc(40px + 100%);
  }

  @include h.breakpoint-l {
    --pullquote--large--margin: #{h.rem(48px 0 48px 0)};
    --pullquote--quote--large--font-size: 3rem;
  }

  @include h.breakpoint-x {
    --pullquote--large--margin: #{h.rem(48px 0 48px -100px)};
    --pullquote--quote--large--font-size: 3.75rem;
  }
}

body.think {
  --pullquote--quote--font-style: italic;
  --pullquote--quote--line-height: 1.25;
  --pullquote--quote--large--line-height: 1.25;
}

body.select {
  --pullquote--border-image: linear-gradient(to right, transparent, transparent 35px, var(--blue-60) 35px) 1;
  --pullquote--quote--color: #2a2a2a;
  --pullquote--quote--font-size: #{h.rem(30px)};
  --pullquote--quote--small--font-size: #{h.rem(30px)};
  --pullquote--quote--large--font-size: #{h.rem(30px)};
  --pullquote--quote--large--line-height: 1.2;
  --pullquote--quote--font-weight: 900;
  --pullquote--quote--line-height: 1.2;
  --pullquote--quote-before--background-color: var(--blue-60);
  --pullquote--attribution--font-family: var(--default-text-font-family);
  --pullquote--attribution--font-size: #{h.rem(14px)};
  --pullquote--attribution--font-weight: normal;
  --pullquote--attribution--line-height: 1.5;
  --pullquote--quip--color: #555;

  @include h.breakpoint-l {
    --pullquote--quote--large--line-height: #{ h.rem(60px)};
    --pullquote--quote--large--font-size: #{h.rem(52px)};
  }
}

body.today {
  --pullquote--margin: #{h.rem(60px)} 0;
  --pullquote--border-image: linear-gradient(to right, transparent, transparent) 1;
  --pullquote--quote--color: var(--secondary-color);
  --pullquote--quote--padding: #{h.rem(32px)} 0 0;
  --pullquote--quote-before--background-color: var(--accent-blue);
  --pullquote--quote-before--small--height: 34px;
  --pullquote--quote-before--small--width: 34px;
  --pullquote--quote-before--height: 34px;
  --pullquote--quote-before--width: 34px;
  --pullquote--quote--font-family: var(--primary-font);
  --pullquote--quote--font-style: normal;
  --pullquote--attribution--font-family: var(--secondary-font);
  --pullquote--attribution--margin: #{h.rem(20px)} 0 0;
  --pullquote--attribution--margin--large: #{h.rem(20px)} 0 0;
  --pullquote--attribution--font-weight: 700;
  --pullquote--quip--color: var(--grey-60);
  --pullquote--quote--line-height: 1.5;
  --pullquote--small--padding: #{h.rem(10px 0 25px)};
  --pullquote--large--padding: #{h.rem(10px 0 25px)};
  --pullquote--padding: #{h.rem(10px 0 25px)};
  --pullquote--quote--large--line-height: 1.5;
  --pullquote--quote--font-size: #{h.rem(28px)};
  --pullquote--quote--large--font-size: #{h.rem(28px)};
  --pullquote--quote--small--font-size: #{h.rem(28px)};

  @include h.breakpoint-m {
    --pullquote--large--margin: #{h.rem(60px 0 60px -60px)};
    --pullquote--quote-before--small--height: 40px;
    --pullquote--quote-before--small--width: 40px;
    --pullquote--quote-before--height: 44px;
    --pullquote--quote-before--width: 44px;
    --pullquote--small--padding: #{h.rem(10px 10px 25px 0)};
    --pullquote--large--padding: #{h.rem(0 0 50px)};
    --pullquote--quote--large--font-size: #{h.rem(32px)};
    --pullquote--quote--small--font-size: #{h.rem(24px)};
  }

  @include h.breakpoint-l {
    --pullquote--large--margin: #{h.rem(60px 0 60px 0)};
    --pullquote--large--padding: #{h.rem(0 0 80px)};
    --pullquote--quote--large--font-size: #{h.rem(48px)};
    --pullquote--quote--font-size: #{h.rem(32px)};
    --pullquote--quote--small--font-size: #{h.rem(28px)};
  }

  @include h.breakpoint-x {
    --pullquote--large--margin: #{h.rem(60px 0 60px -100px)};
    --pullquote--quote--line-height: #{ h.rem(40px)};
  }
}
