@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.liftout {
  border-color: var(--liftout--border-color);
  border-image: var(--liftout--border-image);
  position: relative;
  margin: var(--liftout--margin);

  @include h.map-to-responsive-rules((
    border-style: t.$liftout--border-style,
    border-width: t.$liftout--border-width,
  ));

  body.today &::before {
    background: var(--grey-20);
    content: '';
    display: block;
    position: absolute;
    width: var(--liftout--before--width);
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-2deg) skew(-2deg);
  }
}

.liftout--small {
  @include h.map-to-responsive-rules((
    clear: t.$liftout--small--clear,
    float: t.$liftout--small--float,
    width: t.$liftout--small--width,
  ));
  margin: var(--liftout--small--margin);

  // Should this live here?
  .LiveBlogCard & {
    margin-left: 0;
  }

  body.today & {
    &::before {
      @include h.breakpoint-m {
        width: calc(100% + 60vw);
        left: -60vw;
        top: h.rem(10px);
        transform: rotate(-2deg) skew(-2deg);
      }

      @include h.breakpoint-x {
        top: h.rem(5px);
      }

      @include h.breakpoint-xx {
        top: .7vw;
      }
    }
  }
}

.liftout--large {
  @include h.map-to-responsive-rules((
    width: t.$liftout--large--width,
  ));
  margin: var(--liftout--large--margin);

  body.today &::before {
    width: 100vw;

    @include h.breakpoint-l {
      transform: translate(-50%, -50%) rotate(-1.2deg) skew(-1.2deg);
    }
  }
}

.liftout__text {
  color: var(--liftout--text--color);
  font-family: var(--liftout--text--font-family);
  font-size: var(--liftout--text--font-size);
  font-style: var(--liftout--text--font-style);
  font-weight: var(--liftout--text--font-weight);
  line-height: var(--liftout--text--line-height);
  padding: var(--liftout--text--padding);
  position: relative;

  @include h.responsive-variations(margin, t.$liftout--text--margin);
}

.liftout--small .liftout__text {
  font-size: var(--liftout--small--text--font-size);
}

.liftout--large .liftout__text {
  line-height: var(--liftout--large--text--line-height);
  font-size: var(--liftout--large--text--font-size);
  padding: var(--liftout--large--text--padding);
}
