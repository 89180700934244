@use 'assets/styles/utils/helper' as h;

.container {
  :global(body.today) & {
    --quick-take-superlative-color: #7c4e9f;
    --quick-take-superlative-font-family: var(--secondary-font);
    --quick-take-superlative-font-weight: 700;
    --quick-take-superlative-font-size: 14px;
    --quick-take-superlative-line-height: 150%;
    --quick-take-product-name-font-family: var(--secondary-font);
    --quick-take-product-name-font-weight: 400;
    --quick-take-product-name-font-size: 16px;
    --quick-take-product-name-line-height: 150%;
    --quick-take-product-name-color: #2a2a2a;
    --quick-take-list-price-color: #555;
    --quick-take-list-price-font-family: var(--secondary-font);
    --quick-take-list-price-font-weight: 700;
    --quick-take-list-price-font-size: 17px;
    --quick-take-list-price-line-height: 100%;
    --quick-take-list-price-on-sale-font-size: 12px;
    --quick-take-list-price-on-sale-font-weight: 400;
    --quick-take-sale-price-color: #d53427;
    --quick-take-sale-price-font-family: var(--secondary-font);
    --quick-take-sale-price-font-weight: 700;
    --quick-take-sale-price-font-size: 17px;
    --quick-take-sale-price-line-height: 100%;
    --quick-take-seller-name-color: #2a2a2a;
    --quick-take-seller-name-font-family: var(--secondary-font);
    --quick-take-seller-name-font-weight: 400;
    --quick-take-seller-name-font-size: 16px;
    --quick-take-seller-name-line-height: 100%;
    --quick-take-seller-name-border-bottom: 2px solid #ffb186;
    --quick-take-learn-more-color: #2a2a2a;
    --quick-take-learn-more-font-family: var(--secondary-font);
    --quick-take-learn-more-font-weight: 400;
    --quick-take-learn-more-font-size: 12px;
    --quick-take-learn-more-line-height: 150%;
    --quick-take-learn-more-arrow-color: #ff503c;
  }

  :global(body.select) & {
    --quick-take-superlative-color: #0477c9;
    --quick-take-superlative-font-family: 'Lato', Arial, Helvetica, sans-serif;
    --quick-take-superlative-font-weight: 400;
    --quick-take-superlative-font-size: 14px;
    --quick-take-superlative-line-height: 17px;
    --quick-take-product-name-font-family: 'Lato', Arial, Helvetica, sans-serif;
    --quick-take-product-name-font-weight: 700;
    --quick-take-product-name-font-size: 15px;
    --quick-take-product-name-line-height: 150%;
    --quick-take-product-name-color: #2a2a2a;
    --quick-take-list-price-color: #555;
    --quick-take-list-price-font-family: 'Lato', Arial, Helvetica, sans-serif;
    --quick-take-list-price-font-weight: 700;
    --quick-take-list-price-font-size: 17px;
    --quick-take-list-price-line-height: 100%;
    --quick-take-list-price-on-sale-font-size: 12px;
    --quick-take-list-price-on-sale-font-weight: 400;
    --quick-take-sale-price-color: #cb1111;
    --quick-take-sale-price-font-family: 'Lato', Arial, Helvetica, sans-serif;
    --quick-take-sale-price-font-weight: 700;
    --quick-take-sale-price-font-size: 17px;
    --quick-take-sale-price-line-height: 100%;
    --quick-take-seller-name-color: #555;
    --quick-take-seller-name-font-family: 'Lato', Arial, Helvetica, sans-serif;
    --quick-take-seller-name-font-weight: 400;
    --quick-take-seller-name-font-size: 14px;
    --quick-take-seller-name-line-height: 120%;
    --quick-take-seller-name-border-bottom: 1px solid #0477c9;
    --quick-take-learn-more-color: #2a2a2a;
    --quick-take-learn-more-font-family: 'Lato', Arial, Helvetica, sans-serif;
    --quick-take-learn-more-font-weight: 400;
    --quick-take-learn-more-font-size: 12px;
    --quick-take-learn-more-line-height: 150%;
    --quick-take-learn-more-arrow-color: #0477c9;
  }
}
