@use 'assets/styles/utils/helper' as h;

.mediaContainer {
  z-index: 2;

  @include h.breakpoint-x {
    position: absolute;
    left: -100px;
    top: 0;
  }

  @include h.breakpoint-m {
    margin-right: 8px;
  }

  & .mediaItem {
    margin-right: h.rem(8px);

    &.mobile {
      width: h.rem(72px);
      height: h.rem(72px);
    }

    &:not(.mobile) {
      width: h.rem(88px);
      height: h.rem(88px);
      margin-bottom: 2px;
    }
  }

  .selected {
    border-bottom-color: var(--default-color);
    border-bottom-width: 4px;
    border-bottom-style: solid;

    :global(body.msnbc) & {
      border-bottom-color: var(--blue-70);
    }

    @include h.breakpoint-m {
      border: none;
    }

    :global(body.select) &,
    :global(body.today) & {
      position: relative;
      border: none;

      &::after {
        content: '';
        border-bottom: 10px solid var(--secondary-60);
        bottom: 0;
        left: 0;
        position: absolute;
        width: 100%;
      }
    }

    :global(body.today) & {
      border-bottom-color: var(--purple-70);

      &::after {
        border-bottom: 10px solid var(--secondary-60);
      }
    }

    :global(body.select) & {
      border-bottom-color: var(--default-color);

      &::after {
        border-bottom: 5px solid var(--default-color);
      }
    }
  }

  button:focus {
    outline: none;
  }

  :global(body.today) &,
  :global(body.select) & {
    gap: h.rem(8px);

    & .mediaItem {
      margin-right: 0;
      margin-bottom: 0;

      button {
        height: 100%;
      }
    }
  }
}
