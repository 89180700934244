@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss' as t;

.listicle-product__headline {
  font-size: 1.5em;
  color: var(--listicle-product-headline-color);
  font-family: var(--listicle-product-headline-font);
  line-height: var(--listicle-product-headline-line-height);
  font-weight: var(--listicle-product-headline-weight);
  letter-spacing: var(--listicle-product-headline-letter-spacing);
  margin-top: 0;
  margin-bottom: var(--listicle-product-headline-bottom-spacing);

  &:hover {
    opacity: var(--listicle-product-headline-hover-opacity);
  }

  .listicle-product__grid-container--large & {
    @include h.responsive-variations(font-size, (s: 1.5rem, m: 2rem));
  }

  body.entretenimiento &,
  body.shows &,
  body.telemundo & {
    font-size: h.rem(28px);
  }

  body.select & {
    @include h.responsive-variations(font-size, (s: h.rem(18px), m: h.rem(24px)));
  }

  body.today & {
    font-size: h.rem(24px);

    &:hover {
      color: var(--primary-color);
      text-decoration: underline;
      text-decoration-thickness: 2px;
      text-decoration-color: var(--accent-orange);
      text-underline-offset: 7px;
    }

    &.listicle-product__headline--no-description {
      margin-bottom: h.rem(20px);
    }
  }
}

.listicle-product__border {
  position: relative;
  border-color: var(--listicle-product-border-color);
}

.listicle-product__picture-link img {
  height: auto;
}

.listicle-product__grid-container--large {
  position: relative;

  .article-body__content & {
    margin-left: -#{var(--listicle-product-mobile-gutter)};
    margin-right: -#{var(--listicle-product-mobile-gutter)};

    @include h.breakpoint-m {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .listicle-product__grid-row {
    width: 100%;
    max-width: 335px;
    margin: auto;

    @include h.breakpoint-m {
      max-width: unset;
      margin: 0;
    }

    @include h.breakpoint-l {
      width: calc(100% - 80px);
      margin-left: 0;
    }

    @include h.breakpoint-x {
      position: relative;
      width: calc(83.33333% - 73.33333px);
    }
  }

  .listicle-product__picture-link {
    position: relative;
    line-height: 0;

    @include h.breakpoint-l {
      flex-basis: 55%;
      margin-right: var(--listicle-product-image-text-gutter);
    }

    &--isOde {
      flex-shrink: 0;

      @include h.breakpoint-l {
        flex-basis: 464px;
      }

      @include h.breakpoint-x {
        flex-basis: 560px;
      }
    }
  }

  .listicle-product__card-info {
    margin-left: var(--listicle-product-mobile-gutter);
    margin-right: var(--listicle-product-mobile-gutter);

    @include h.breakpoint-m {
      margin-left: 0;
      margin-right: 0;
    }

    @include h.breakpoint-l {
      align-self: center;
      flex-basis: 40%;
    }
  }
}

.listicle-product__grid-container--small {
  position: relative;

  .listicle-product__grid-row {
    max-width: 300px;
    margin: 0 auto;

    @include h.breakpoint-m {
      max-width: none;
    }
  }

  .listicle-product__card-info {
    @include h.breakpoint-m {
      flex-basis: calc(50% - (#{var(--listicle-product-image-text-gutter)} / 2));
      align-self: flex-end;
    }
  }

  .listicle-product__picture-link {
    position: relative;

    @include h.breakpoint-m {
      flex-basis: calc(50% - (#{var(--listicle-product-image-text-gutter)} / 2));
      margin-right: var(--listicle-product-image-text-gutter-small);
      align-self: flex-end;
    }

    @include h.breakpoint-l {
      flex-basis: calc(45% - (#{var(--listicle-product-image-text-gutter)} / 2));
    }

    @include h.breakpoint-x {
      flex-basis: calc(50% - (#{var(--listicle-product-image-text-gutter)} / 2));
    }

    body.today & {
      picture {
        display: block;
      }
    }
  }
}

.listicleProduct__pictureLink {
  z-index: 3;

  @include h.breakpoint-m {
    z-index: initial;
  }
}

body.entretenimiento,
body.shows,
body.telemundo {
  .listicle-product__grid-container--small {
    .listicle-product__grid-row {
      max-width: var(--listicle-product-grid-row-max-width);

      @include h.breakpoint-m {
        max-width: none;
      }
    }
  }
}

body.today,
body.entretenimiento,
body.shows,
body.telemundo {
  .listicle-product__grid-container--small {
    .listicle-product__card-container {
      border-bottom: solid var(--listicle-product-border-width) var(--listicle-product-border-color);
      padding-left: var(--listicle-product-mobile-spacer);
      margin-right: var(--listicle-product-mobile-spacer);

      @include h.breakpoint-m {
        margin-right: 0;
        padding-left: 0;
        padding-bottom: var(--listicle-product-tablet-spacer);
        position: relative;

        &::after {
          content: var(--listicle-product-card-container-border-after-content);
          display: block;
          position: absolute;
          border-color: var(--listicle-product-border-color);
          border-style: solid;
          border-width: 1px 0 1px 1px;
          height: calc(30% + #{var(--listicle-product-tablet-spacer)});
          bottom: -1px;
          width: var(--listicle-product-tablet-spacer);
          left: calc(#{var(--neg-listicle-product-tablet-spacer)} - 1px);
        }
      }

      @include h.breakpoint-l {
        margin-left: var(--listicle-product-tablet-spacer);
      }

      @include h.breakpoint-x {
        margin-left: 0;
      }
    }

    .listicle-product__card-info {
      border-width: 0;
      position: relative;

      &::after {
        content: var(--listicle-product-card-container-border-after-content);
        display: block;
        position: absolute;
        border-color: var(--listicle-product-border-color);
        border-style: solid;
        border-width: 1px 0 1px 1px;
        height: calc(100% + 100px);
        bottom: -1px;
        width: var(--listicle-product-mobile-spacer);
        left: calc(#{var(--neg-listicle-product-mobile-spacer)} - 1px);
      }

      @include h.breakpoint-m {
        padding: 0;
        margin-left: 0;

        &::after {
          display: none;
        }
      }
    }
  }

  .listicle-product__grid-container--large {
    .listicle-product__card-info {
      margin-left: var(--listicle-product-card-info-margin-left);
      margin-right: var(--listicle-product-card-info-margin-right);
      border-width: 0;
      position: relative;
      padding-bottom: var(--listicle-product-mobile-spacer);
      border-bottom: solid var(--listicle-product-border-width) var(--listicle-product-border-color);

      &::after {
        content: var(--listicle-product-card-container-border-after-content);
        display: block;
        position: absolute;
        border-color: var(--listicle-product-border-color);
        border-style: solid;
        border-width: 1px 0 1px 1px;
        height: calc(100% + 100px);
        bottom: -1px;
        width: var(--listicle-product-mobile-spacer);
        left: calc(#{var(--neg-listicle-product-mobile-spacer)} - 1px);
      }

      @include h.breakpoint-m {
        padding-bottom: var(--listicle-product-tablet-spacer);
        margin-left: 0;
        margin-right: 0;

        &::after {
          width: var(--listicle-product-tablet-spacer);
          left: calc(#{var(--neg-listicle-product-tablet-spacer)} - 1px);
        }
      }

      @include h.breakpoint-l {
        padding-top: 0;
        padding-bottom: 0;
        border-width: 0;

        &::after {
          display: none;
        }
      }
    }

    .listicle-product__card-container {
      @include h.breakpoint-l {
        padding-bottom: var(--listicle-product-tablet-spacer);
        border-bottom: solid var(--listicle-product-border-width) var(--listicle-product-border-color);
        position: relative;

        &::after {
          content: var(--listicle-product-card-container-border-after-content);
          display: block;
          position: absolute;
          border-color: var(--listicle-product-border-color);
          border-style: solid;
          border-width: 1px 0 1px 1px;
          height: calc(#{var(--listicle-product-tablet-spacer)} * 2);
          bottom: -1px;
          width: var(--listicle-product-tablet-spacer);
          left: calc(#{var(--neg-listicle-product-tablet-spacer)} - 1px);
        }
      }

      @include h.breakpoint-x {
        &::after {
          height: calc(#{var(--listicle-product-tablet-spacer)} * 4);
        }
      }
    }
  }
}

.listicle-product__seller-name {
  color: var(--grey-60);
  font-family: var(--listicle-product-seller-name-font);
  font-size: h.rem(14px);
  line-height: 1;
  margin: 0 0 12px;
  padding: 0;
}

.listicle-product__description {
  @include h.responsive-variations(font-size, (s: 1rem, m: 1.125rem));
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: var(--listicle-product-description-margin-bottom);
  color: var(--listicle-product-description-color);

  body.entretenimiento &,
  body.shows &,
  body.telemundo &,
  body.select & {
    font-size: h.rem(14px);
  }

  &--large {
    color: var(--listicle-product-description-color--large);
  }
}

.listicle-product__mobile-media {
  width: 100vw;
  z-index: 2;

  @include h.breakpoint-m {
    display: none;
  }

  .slider-slide {
    width: 100%;
    max-width: 100%;
  }

  .listicle-product__mobile-media-item {
    width: 100%;
    flex-shrink: 0;
  }
}

.listicle-product__button {
  &:not(:last-child) {
    margin-bottom: var(--listicle-product-button-margin-bottom);
  }

  &:hover {
    opacity: 1;
  }
}

.listicle-product__cost {
  @include h.responsive-variations(font-size, (s: inherit, m: 1rem));
  border-radius: var(--listicle-product-cost-border-radius);
  font-family: var(--listicle-product-cost-font-family);
  font-weight: var(--listicle-product-cost-font-weight);
  letter-spacing: var(--listicle-product-cost-letter-spacing);
  line-height: var(--listicle-product-cost-line-height);
  min-width: 100px;

  body.select & {
    font-size: h.rem(17px);
  }

  body.today & {
    font-size: h.rem(16px);
  }
}

.listicle-product__vendor {
  border-radius: var(--listicle-product-vendor-border-radius);
  font-family: var(--listicle-product-vendor-font-family);
  font-size: var(--listicle-product-vendor-font-size);
  letter-spacing: var(--listicle-product-vendor-letter-spacing);
  line-height: var(--listicle-product-vendor-line-height);
  max-width: h.rem(225px);
  justify-content: var(--listicle-product-vendor-justify-content);
  padding-top: 0;
  padding-bottom: 0;

  body.select & {
    max-width: h.rem(235px);
  }

  @include h.breakpoint-m {
    max-width: h.rem(250px);
  }
}
