@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.menu-embed {
  overflow: var(--menu-embed-overflow);

  @include h.breakpoint-x {
    width: var(--menu-embed-width);
    margin-left: var(--menu-embed-margin);
  }

  &__title {
    display: var(--menu-title-display);
    background-color: var(--menu-title-background-color);
    color: var(--menu-title-color);
    font-family: var(--menu-title-font-family);
    font-size: var(--menu-title-font-size);
    font-style: var(--menu-title-font-style);
    font-weight: var(--menu-title-font-weight);
    line-height: var(--menu-title-leading);
    margin: var(--menu-title-margin);
    white-space: nowrap;

    &--default {
      &::after {
        display: var(--menu-title-after-display);
        align-self: var(--menu-title-after-align-self);
        background-color: var(--menu-title-after-background-color);
        background-image: var(--menu-title-after-background-gradient);
        background-repeat: var(--menu-title-after-background-repeat);
        background-size: var(--menu-title-after-background-size);
        content: var(--menu-title-after-content);
        flex: 0 1 auto;
        height: var(--menu-title-after-height);
        margin-left: var(--menu-title-after-margin-left);
        width: var(--menu-title-after-width);
      }

      @include h.breakpoint-m {
        padding: var(--menu-title-padding);
        min-width: var(--menu-title-min-width);
        border-top: var(--menu-title-border);
        border-left: var(--menu-title-border);
        position: relative;

        &::after {
          display: var(--menu-title-after-display);
          border-right: var(--menu-title-border);
          border-bottom: var(--menu-title-border);
          position: absolute;
          top: var(--menu-title-after-top);
          right: var(--menu-title-after-right);
          left: var(--menu-title-after-left);
          bottom: var(--menu-title-after-bottom);
        }
      }
    }

    &--custom {
      @include h.title-underline(
        var(--accent-yellow),
        var(--menu-title-font-size),
        var(--menu-title-leading)
      );
    }
  }

  &__link a {
    color: var(--menu-items-color);

    &:hover {
      opacity: var(--menu-items-hover-opacity);
      color: var(--menu-items-hover-color);
    }

    &:active {
      opacity: var(--menu-items-hover-opacity);
    }

    body.today & {
      text-decoration: underline;
      text-decoration-color: var(--accent-orange);
      text-decoration-thickness: 2px;
      text-underline-offset: 5px;
    }
  }

  &__items {
    font-size: var(--menu-items-font-size);
    line-height: var(--menu-items-line-height);
    font-family: var(--menu-items-font-family);
    font-weight: var(--menu-items-font-weight);
    font-style: var(--menu-items-font-style);

    li {
      margin-bottom: var(--menu-items-spacing);

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    @include h.breakpoint-s-only {
      border-top: var(--menu-items-border);
      border-bottom: var(--menu-items-border);
      padding: var(--menu-items-padding);
    }

    @include h.breakpoint-m {
      background-color: var(--menu-items-background-color);
      padding: var(--menu-items-padding);
      position: relative;
      margin-left: var(--menu-items-margin-left);

      &::after {
        content: '';
        display: var(--menu-items-after-display);
        height: var(--menu-items-after-height);
        left: calc(-1 * var(--space-40));
        position: absolute;
        top: 0;
        width: var(--space-40);
        background-color: var(--menu-title-background-color);
        border-left: var(--menu-title-border);
        border-bottom: var(--menu-title-border);
      }
    }
  }

  .animated-ghost-button {
    height: h.rem(54px);
    width: h.rem(158px);
    padding: 18px 8px;
    margin-top: 20px;
    border-radius: var(--menu-items-see-more-border-radius);

    body.select & {
      color: #0477c9;
      border-color: #0477c9;

      &:hover {
        background-image: linear-gradient(35deg, #0477c9 50%, transparent 0);
        background-color: #0477c9;
        color: var(--white);
      }
    }

    @include h.breakpoint-m {
      width: h.rem(193px);
      margin-top: 30px;
    }
  }
}
