@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss' as t;

.related-item {
  position: relative;
  display: flex;
  border-top: var(--related-item-border-top);
  border-bottom: var(--related-item-border-bottom);

  button {
    border: none;
    background: none;
    appearance: none;
    padding-left: 0;
    padding-right: 0;
  }

  a {
    text-decoration: none;
  }
  // don't want article body link treatment to be honored for thumbnails and titles
  div a:active,
  div a:hover {
    opacity: initial;
  }

  &.related-item__single {
    width: 100%;
    border-top: none;
    border-bottom: none;
    background-image: none;
    padding-top: 14px;

    body.today & {
      @include h.breakpoint-m {
        padding-bottom: 1.75rem;
      }

      @include h.breakpoint-l {
        padding: 0.75rem 0 0.5rem;
      }

      &.related-item__no-art {
        padding: 0;
      }
    }

    @include h.breakpoint-m {
      padding-top: 0;
    }
  }

  &:not(.related-item__single) {
    padding: h.rem(20px) 0;
    flex-basis: 100%;

    &:first-of-type {
      margin-top: h.rem(12px);
    }

    body.today & {
      border-top: none;
      background-image: var(--related-item-background-image);
      background-size: 250px 1px;
      background-repeat: no-repeat;
      background-position: left top;
    }

    @include h.ie {
      &.related-item__is-trending {
        flex: 0 0 auto;
        min-height: 205px;
        border-top: 0.1px dotted var(--grey-60);
      }

      &.related-item__is-nbc-news-commerce {
        min-height: auto;
      }

      &:first-of-type {
        margin-top: 0;
      }
    }

    @include h.breakpoint-m {
      flex: 0 0 calc(50% - 16px);

      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  &.related-item__default:not(.related-item__single) {
    body.today & {
      background-image: none;
    }
  }
}

.related-item__link {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  display: block;
  font: inherit;
  outline: inherit;
  padding: 0;
  text-align: left;

  &:not(.related-item__picture-link) {
    line-height: var(--related-item-link-line-height);

    &:hover {
      opacity: 0.7;
    }
  }

  &.related-item__picture-link {
    line-height: 0;
  }

  body.today &:hover {
    opacity: 1;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-decoration-color: var(--accent-orange);
    text-underline-offset: 4px;
  }
}

.related-item__picture-link {
  font-size: 0;
}

.related-item__thumbnail-wrapper {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  align-self: flex-start;
  margin-right: var(--related-item-thumbnail-wrapper-margin-right);

  @include h.breakpoint-m {
    flex-basis: h.rem(80px);

    .related-item__single & {
      margin-right: 0;
      flex-basis: h.rem(280px);

      body.today & {
        flex-basis: h.rem(260px);
      }
    }
  }

  @include h.breakpoint-x {
    .related-item__single & {
      flex-basis: h.rem(320px);
    }
  }
}

.related-item__thumbnail__image {
  display: block;
  overflow: auto;
  border-radius: var(--related-item-thumbnail-image-border-radius);

  img {
    height: auto;
  }

  .related-item__single & {
    @include h.breakpoint-m {
      border-radius: 0;
    }
  }
}

.related-item__thumbnail__image--raw {
  width: 80px;
  height: 80px;

  .related-item__single & {
    @include h.breakpoint-m {
      width: unset;
      height: auto;
      max-width: 280px;
    }

    @include h.breakpoint-x {
      max-width: 320px;
    }
  }
}

.related-item__info-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .related-item__single & {
    width: 100%;

    @include h.breakpoint-m {
      padding: h.rem(20px);

      body.today & {
        padding: 0 h.rem(30px);
      }
    }
  }

  body.today .related--single .related-item__no-art & {
    padding: h.rem(20px) 0;
  }
}

.related-item__eyebrow {
  font-family: var(--related-item-eyebrow-font-family);
  font-weight: var(--related-item-eyebrow-font-weight);
  font-size: h.rem(12px);
  line-height: var(--related-item-eyebrow-line-height);
  letter-spacing: var(--related-item-eyebrow-letter-spacing);
  color: var(--related-item-eyebrow-color);
  display: block;
  position: relative;
  text-transform: uppercase;
  z-index: 2;

  &:hover {
    color: var(--related-item-eyebrow-hover-color);
  }

  .related-item__info-wrapper & {
    padding: var(--related-item-eyebrow-wrapper-padding);
  }

  .related-item__single .related-item__info-wrapper & {
    @include h.breakpoint-m {
      display: none;
    }
  }

  .related-item__single .related-item__thumbnail-wrapper & {
    display: block;

    @include h.breakpoint-m {
      display: flex;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}

.related-item__label {
  display: none;
}

.related-item__single .related-item__label {
  display: none;

  @include h.breakpoint-m {
    background-color: var(--related-item-single-label-bg-color);
    display: block;
    flex: 0 0 h.rem(132px);
    padding: h.rem(16px 16px 0);
    height: h.rem(32px);
    white-space: nowrap;
  }
}

.related-item__icon {
  bottom: 0;
  left: 0;

  .related-item__single & {
    @include h.breakpoint-m {
      position: relative;
      top: unset;
      left: unset;
      flex: 0 0 h.rem(32px);
    }
  }
}

.related-item__title {
  color: var(--related-item-title-color);
  font-family: var(--related-item-title-font);
  font-weight: var(--related-item-title-font-weight);
  font-size: var(--related-item-title-font-size);
  line-height: var(--related-item-title-font-line-height);
  margin: 0;

  .related-item__single & {
    font-size: var(--related-item-title-single-font-size);
    line-height: var(--related-item-title-single-font-line-height);
  }

  body.today .related-item__single.related-item__no-art & {
    font-size: h.rem(18px);
  }
}

body.today {
  .related-item__thumbnail__image:hover {
    transform: rotate(-2deg);
  }

  .related-item__default {
    &:not(.related-item__no-art) .related-item__thumbnail__image {
      width: 90px;
    }
    // for single related item
    &.related-item__single {
      @include h.breakpoint-m {
        .related-item__thumbnail__image {
          width: auto;
        }
      }
    }
  }

  .related-item__single .related-item__thumbnail-wrapper .related-item__eyebrow {
    display: none;
  }

  .related-item__single:not(.related-item__no-art) .related-item__info-wrapper .related-item__eyebrow {
    @include h.breakpoint-m {
      display: block;
    }
  }

  .related-item__has-tandem-hover:has(.related-item__link:hover) {
    .related-item__link {
      opacity: 1;
      text-decoration: underline;
      text-decoration-thickness: 2px;
      text-decoration-color: var(--accent-orange);
      text-underline-offset: 4px;
    }

    .related-item__thumbnail__image {
      transform: rotate(-2deg);
    }
  }
}
